import React from "react";
import "./style.css";

const Description = ({ context, className }) => {
  return (
    <div>
      <p className={className}>{context}</p>
    </div>
  );
};

export default Description;
