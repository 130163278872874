import React from "react";
import classes from "./productDevelopment.module.css";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";

const ProductDevelopment = ({ heading, description }) => {
  return (
    <div className={classes.descriptionContainer}>
      <div className={classes.descriptionSubContainer}>
        <Heading heading={heading} />
        <div className="">
          <Description description={description} />
        </div>
      </div>
    </div>
  );
};

export default ProductDevelopment;
