import coverImage from "../../../image/CaseStudy/istills/coverImage/coverImage.jpg";
import customerImage1 from "../../../image/CaseStudy/istills/customerJourney/image1.jpg";
import customerImage2 from "../../../image/CaseStudy/istills/customerJourney/image2.jpg";
import wireframeImage1 from "../../../image/CaseStudy/istills/wireframe/image1.jpg";
import wireframeImage2 from "../../../image/CaseStudy/istills/wireframe/image2.jpg";

const istillsCaseStudyData = [
  {
    componentType: "title",
    context: " Rental E-commerce iStills.in",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    url: coverImage,
    className: "coverImg"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `istills.in is a platform that allows various photographic enthusiasts to rent equipment that are very expensive at affordable price for professionals in this field, and in-turn provide a way for photography professional to deliver the best service to their customer.`,
    className: "content"
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Requirement",
    className: "sub-heading"
  },

  {
    componentType: "liList",
    id: "requirementList",
    description: `istills.in is a platform that allows various photographic enthusiasts to rent equipment that are very expensive at affordable price for professionals in this field, and in-turn provide a way for photography professional to deliver the best service to their customer.`,
    listItem: [
      "Branding Design",
      "Need editable eCom product catalogue",
      "Rental logic to support available inventory",
      "A web/mobile responsive site access for customers",
      "Customer payment gateway"
    ],
    className: ["requirement-container", "arrow", "requirement-content"]
  },
  {
    componentType: "title",
    id: "solutions",
    context: "Solution",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `We took our not so technical client from a complete no technology background to manage an e-commerce portal that allows a rental business model for this industry.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `We not only helped istills.in branding creation, but also defined the whole online strategy that will allow them to flourish even in a tough rental market that is still to attain its potential in the industry.    `,
    className: "content"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `istills.in provides both services to put your cameras on rent to hire camera on rent for a specific period. We also created a custom logic for istills to handle inventory that may be hired by multiple individuals in specified period.`,
    className: "content"
  },
  {
    componentType: "title",
    id: "branding",
    context: "Branding",
    className: "sub-heading"
  },
  {
    componentType: "BrandImage",
    id: "brandingLogo",
    url: "	https://deskala.in/wp-content/uploads/2021/08/iStills-Logo_hi-res-1-1-581x200.png",
    className: "brand-logo"
  },

  {
    componentType: "title",
    id: "carouselFirstTitle",
    context: "CUSTOMER JOURNEY/INFORMATION ARCHITECTURE",
    className: "sub-heading"
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [customerImage1, customerImage2],
    className: ["carousel-wrapper", "carousel-image-wrapper", "carousel-image"]
  },
  {
    componentType: "title",
    id: "carouselSecondTitle",
    context: "WIREFRAMES & VISUAL DESIGN",
    className: "sub-heading"
  },
  {
    componentType: "imageSlider",
    id: "carouselSecond",
    url: [wireframeImage1, wireframeImage1],
    className: ["carousel-wrapper", "carousel-image-wrapper", "carousel-image "]
  },

  {
    componentType: "title",
    id: "benifits",
    context: "Benefits",
    className: "sub-heading"
  },

  {
    componentType: "liList",
    id: "benifitList",
    description: `iStills has been our long term customer`,
    listItem: [
      "rental e-commerce automation",
      "Shopify based website",
      "Inventory updation from Excel data sheet",
      "Customer ticket processing"
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "title",
    id: "feedback",
    context: "Feedback",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: `“Deskala team did a commendable job in designing our website. They understood our domain of Photography rentals and user community to come up with a design that is elegant and easy for customers to use. Deskala also did our logo and branding which helped to establish our brand in the rental market. We would recommend Deskala for any product design consulting.”`,
    className: "content"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: "Prabhakaran S, CEO and Founder of iStills.in",
    className: "content"
  }
];

export default istillsCaseStudyData;
