import coverImage from "../../../image/CaseStudy/skalebot/coverImage/coverImage.jpg";
import brandImg from "../../../image/CaseStudy/udemy/brandImage/brandImage.jpg";
import image1 from "../../../image/CaseStudy/skalebot/image1.jpg";
import image2 from "../../../image/CaseStudy/skalebot/image2.jpg";
import image3 from "../../../image/CaseStudy/skalebot/image3.jpg";
import image4 from "../../../image/CaseStudy/skalebot/image4.jpg";
import image5 from "../../../image/CaseStudy/skalebot/image5.jpg";
import image6 from "../../../image/CaseStudy/skalebot/image6.jpg";
import image7 from "../../../image/CaseStudy/skalebot/image7.jpg";
import image8 from "../../../image/CaseStudy/skalebot/image8.jpg";

const udemyCaseStudyData = [
  {
    componentType: "title",
    context: "Skalebot - User-Friendly Chatbot for Businesses",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    url: coverImage,
    className: "coverImg"
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Business Problem",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Many businesses often encounter several challenges in their operations, hindering their ability to provide efficient customer support and optimize sales opportunities. The problems they face can be summarized as follows :`,
    className: "content"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `Difficulty in sharing product images efficiently with potential customers via WhatsApp.`,
      `Inadequate round-the-clock customer support, resulting in delayed responses and customer dissatisfaction.`,
      `Time-consuming manual process of individually sharing product images, leading to inefficiency.`,
      `Absence of a centralized system to effectively manage and track customer inquiries and leads.`,
      `High cost marketing.`,
      `Limited features and capabilities of WhatsApp Business accounts.`
    ]
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Requirement",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `When seeking a solution to these common challenges, businesses typically look for the following key requirements :`,
    className: "content"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `Simple, User-Friendly, and Affordable`,
      `Available 24/7`,
      `Analytics and Reports`,
      `Scalability`,
      `Seamless Integration`,
      `Real-Time Notifications`
    ]
  },
  {
    componentType: "title",
    id: "solutions",
    context: "Solution",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `To meet these requirements, Deskala introduced a solution called Skalebot - an AI-driven chatbot integrated with WhatsApp. Skalebot addresses these common challenges and fulfills the requirements with the following features:`,
    className: "content"
  },
  {
    componentType: "title",
    id: "benifits",
    context: "User Friendly Chatbot:",
    className: "sub-context"
  },
  {
    componentType: "liList",
    id: "benifitList",

    listItem: [
      "Skalebot offers a user-friendly interface, enabling easy implementation and eliminating the need complex working."
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: image1,
    className: "image"
  },
  {
    componentType: "title",
    context: "Agent Support:",
    className: "sub-context"
  },
  {
    componentType: "liList",
    id: "benifitList",

    listItem: [
      "Skalebot allows agents to handle customer interactions simultaneously, optimizing response time and efficiency."
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    // url: "https://deskala.in/wp-content/uploads/2021/09/api-doc.png",
    url: image2,
    className: "image"
  },
  {
    componentType: "title",
    context: "Scalability & Availability:",
    className: "sub-context"
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      " Skalebot efficiently handled a large volume of customer inquiries simultaneously, ensuring scalability during surges in inquiries.",
      " Skalebot operates 24/7, providing instant responses regardless of the time of day, thus increasing sales opportunities."
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: image3,
    className: "image"
  },
  {
    componentType: "title",
    context: "Analytical High Open rate:",
    className: "sub-context"
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      " Skalebot provided comprehensive analytics and insights to track customer engagement and optimize marketing strategies."
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: image4,
    className: "image"
  },
  {
    componentType: "title",
    context: "API Support:",
    className: "sub-context"
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      " Skalebot seamlessly integrated with existing systems through API support, ensuring smooth workflow management."
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: image5,
    className: "image"
  },
  {
    componentType: "title",
    context: "Real-time whatsapp notifications:",
    className: "sub-context"
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      " Skalebot provided instant notifications of customer queries, ensuring prompt responses and enhancing customer satisfaction."
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: image6,
    className: "image"
  },
  {
    componentType: "title",
    context: "Broadcast Messages:",
    className: "sub-context"
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      " Businesses could leverage Skalebot to send broadcast messages, enhancing marketing efforts and customer engagement."
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: image7,
    className: "image"
  },
  {
    componentType: "title",
    id: "feedback",
    context: "Feedback",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Businesses that have adopted solutions like Skalebot typically report significant improvements in their operations and customer support processes. These solutions have transformed their approach to customer interactions, resulting in increased efficiency, cost savings, and improved customer satisfaction.
    `,
    className: "content"
  }
];

export default udemyCaseStudyData;
