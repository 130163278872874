import React from "react";
import { ReactComponent as PhoneSvg } from "../../image/socialLogos/contactUsPhoneIcon.svg";
import { ReactComponent as MailSvg } from "../../image/socialLogos/contactUsGmailIcon.svg";
import { ReactComponent as LocationSvg } from "../../image/socialLogos/locationIcon.svg";
import locationSvg from "../../image/socialLogos/locationIcon.svg";
import classes from "./contactDetails.module.css";
import contactUSImage from "../../image/contactUsImage.svg";
const ContactDetails = () => {
  return (
    <div className={` col-xl-3 col-lg-3 col-sm-12 gap-4 ${classes.container}`}>
      <div className={classes.imageContainer}>
        <img
          src={contactUSImage}
          alt="contactusImage"
          title="contactus Image"
          className={classes.contactUsImage}
        />
      </div>
      <div className={classes.subContainer}>
        <div className="d-flex flex-column">
           <h2 className={`${classes.subHeading}`}>Alosia Rose </h2>
          <h4 className={`${classes.subHeadingPosition}`}>Marketing Head</h4>
        </div>
        <div className="d-flex flex-row  justify-content-center justify-content-lg-start  gap-3 mb-1">
          <PhoneSvg className="mt-1" />

          <h3 className={classes.contactNo}>+91 9847536687</h3>
        </div>
        <div className="d-flex flex-row justify-content-center justify-content-lg-start   gap-3  mb-1">
          <MailSvg className="mt-2" />

          <h3 className={classes.contactNo}>sales@deskala.in</h3>
        </div>
        <div className="d-flex flex-row justify-content-center justify-content-lg-start  align-items-start gap-3 mb-1">
          <img src={locationSvg} alt="locationIcon" title="location Icon" className="mt-1" />
          <h3 className={`${classes.contactNo} text-start `}>
            #B-05 MJ Lifestyle Amadeus, S Ave Rd, Rayasandra, Sriramapura,
            Bengaluru, Karnataka 560099
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
