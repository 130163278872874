import React from "react";
import classes from "./workshopCards.module.css";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";

const WorkshopCards = ({ heading, description, cards }) => {
  return (
    <div className={classes.WorkshopContainer}>
      <div className={classes.WorkshopSubContainer}>
        <Heading heading={heading} />
        <Description description={description} />
        <div className={`${classes.scrollableContainer}`}>
          <div className={classes.cardsContainer}>
            {cards.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.anchorTag}
                >
                  {/* <div className={classes.cardWraper}> */}
                  <div className={`${classes.marketingCard}`}>
                    {/* <div className={classes.marketingImageContainer}> */}
                    <img
                      src={item.cardImage}
                      alt="marketing"
                      title="think image"
                      className={classes.marketingImage}
                    />
                    {/* </div> */}
                    <p className={classes.publishBy}>{item.publishBy}</p>
                    <h3 className={classes.publishTitle}>{item.cardTitle}</h3>
                  </div>
                  {/* </div> */}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopCards;
