import React from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import classes from "./contactForm.module.css";

const ContactForm = () => {
  const defaultValues = {
    name: "",
    email: "",
    message: ""
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="text-danger  ">{errors[name].message}</small>
      )
    );
  };
  const sendWhatsAppMessage = (formData) => {
    const { name, email, message } = formData;

    const phoneNumber = "918878183433";
    const messageText = `Name: ${name}%0A email Id: ${email}%0A Message: ${message}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappUrl, "_blank");
  };

  const submithandler = (data) => {
    sendWhatsAppMessage(data);
    setValue("name", "");
    setValue("email", "");
    setValue("message", "");
  };
  return (
    <div className={`col-11 col-xl-5 col-lg-5 col-md-6 ${classes.container}`}>
       <h2 className={classes.title}>Get in touch  </h2>
       <h2 className={classes.subTitle}>
        We are here for you! How can we help you?
       </h2>

      <div className={classes.nameContainer}>
        <Controller
          name="name"
          control={control}
          rules={{ required: "Please provide name" }}
          render={({ field, fieldState }) => (
            <InputText
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              locale="en-IN"
              placeholder=" Enter your name"
              className={` ${classes.inputStyle}
                 ${fieldState.invalid ? "border border-danger" : ""}   `}
            />
          )}
        />
        {getFormErrorMessage("name")}
      </div>
      <div className={classes.nameContainer}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Please provide email",
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "Enter a valid Email Id"
            }
          }}
          render={({ field, fieldState }) => (
            <InputText
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              locale="en-IN"
              placeholder="Enter your email address"
              className={` ${classes.inputStyle}
                 ${fieldState.invalid ? "border border-danger" : ""}   `}
            />
          )}
        />
        {getFormErrorMessage("email")}
      </div>
      <div className={classes.nameContainer}>
        <Controller
          name="message"
          control={control}
          render={({ field, fieldState }) => (
            <InputTextarea
              id={field.name}
              value={field.value}
              rows={5}
              cols={30}
              // autoResize
              onChange={(e) => field.onChange(e.target.value)}
              locale="en-IN"
              placeholder="Enter your message here"
              className={` ${classes.inputStyle} ${classes.messageStyle} `}
            />
          )}
        />
      </div>

      <button
        type="submit"
        className={classes.submitButton}
        onClick={handleSubmit(submithandler)}
      >
        Contact
      </button>
    </div>
  );
};

export default ContactForm;
