import React from "react";
import classes from "./logoAnimation.module.css";
import Marquee from "react-fast-marquee"; // Import the Marquee component
const LogoAnimation = ({ logos, className, style }) => {
  return (
    <div className={classes.logos}>
      <Marquee speed={20}>
        {logos.map((logo, index) => {
          return (
            <img
              key={index}
              src={logo}
              alt="logo"
              title="brand logos"
              className={classes.logosSlideImg}
            />
          );
        })}
      </Marquee>
    </div>
  );
};

export default LogoAnimation;
