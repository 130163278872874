import sliderImage1 from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/slider/image1.jpg";
import sliderImage2 from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/slider/image2.jpg";
import carouselImage1 from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/carousel/image1.jpg";
import carouselImage2 from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/carousel/image2.jpg";
import coverImage from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/coverImage.jpg";
import olaLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/olaLogo.jpg";
import flipkartLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/flipkartLogo.jpg";
import hdfcLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/hdfcLogo.jpg";
import zomatoLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/zomatoLogo.jpg";
import gojekLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/gojekLogo.jpg";

const exotelApiCaseStudyData = [
  {
    componentType: "title",
    context: " Exotel Developer API Console",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    url: coverImage,
    className: "coverImg"
  },

  {
    componentType: "description",
    id: "introductionId",
    context: `Exotel is a secure and reliable business phone system on the cloud. It helps businesses to improve their operational efficiency, build better processes, deliver brilliant customer support and most of all, create happy customers! All of this at scale.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `4,000+ Businesses Use Exotel in India and Southeast Asia `,
    className: "content"
  },
  {
    componentType: "client-logos",
    id: "customers",
    url: [olaLogo, flipkartLogo, hdfcLogo, zomatoLogo, gojekLogo],

    className: [
      "marqueeContainer",
      "marquee",
      "marqueeLogoContainer ",
      "marqueeLogoClient "
    ]
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Requirement",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Exotel has a developer experience portal which provides APIs that can be used by businesses to build high-quality communication applications on the cloud. The developer experience portal needed a console on which developers will be able to understand Exotel’s API and experiment before implementing them in their applications, saving many hours of code research and significantly fewer calls to Exotel’s support team.`,
    className: "content"
  },

  //   {
  //     componentType: "list",
  //     id: "requirementList",
  //     listItem: ["Applets"],
  //     className: ["requirement-container", "right-arrow", "requirement-content"]
  //   },
  {
    componentType: "title",
    id: "solutions",
    context: "Solution",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `We developed a bespoke CMS based framework for Exotel where product managers can easily create a sandbox for respective products’ APIs a readymade console for developers to invoke them directly in.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `We distilled the content into intuitive components and then imported those components into the CMS.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Having such a framework ensures that the same design guidelines and layouts are followed across the portal, besides giving the developers a ready reckoner of APIs and the results of their trials with it.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: (
      <>
        The portal is now available on the link
        <a
          href="https://developer.exotel.com/api-console"
          target="_blank"
          rel="noreferrer"
        >
          https://developer.exotel.com/api-console
        </a>
      </>
    ),
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Our solution resulted in meeting the goals`,
    className: "content"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      "Significant effort saved for developers",
      "Ready reckoners and sample code for developers to use",
      "Tangibly fewer support calls",
      "A seamless experience for viewers",
      "Intuitive interface for editors for generating API centric content",
      "Streamlined the publishing process for the Product Management team"
    ],
    className: ["requirement-container", "arrow", "requirement-content"]
  },
  {
    componentType: "title",
    context: "Component Photo (Editors View)",
    className: "sub-heading"
  },

  {
    componentType: "imageSlider",
    url: [sliderImage1, sliderImage2],
    className: ["carousel-wrapper", "carousel-image-wrapper", "carousel-image"]
  },
  {
    componentType: "title",
    context: "Website Photo (Developers View)",
    className: "sub-heading"
  },

  {
    componentType: "imageSlider",
    url: [carouselImage1, carouselImage2],
    className: ["carousel-wrapper", "carousel-image-wrapper", "carousel-image"]
  },
  {
    componentType: "title",
    id: "benifits",
    context: "Benefits",
    className: "sub-heading"
  },
  // {
  //   componentType: "description",
  //   id: "introductionId",
  //   context: `A  rich, interactive developer experience, including:`,
  //   className: "content"
  // },
  {
    componentType: "liList",
    id: "benifitList",
    description: `A  rich, interactive developer experience, including:`,
    listItem: [
      "The ability to try APIs",
      "Experiment with different parameters within the console",
      "Understand trade-offs of different APIs and parameters much before attempting them in an application",
      "Access API documentation directly from within the console",
      "Use the sample code to get a leg up when developing any application using Exotel API"
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      "Ease of Use and intuitive components for editing",
      "User Access Control",
      "Seamless outreach for Production Management and Marketing teams",
      "Fewer support calls",
      "Reusable components for publishing to CMS"
    ],
    className: ["requirement-container", "arrow", "requirement-content"]
  },
  {
    componentType: "title",
    id: "feedback",
    context: " Feedback",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: `“ The Deskala team has done amazing work in building an API Console for Exotel. Their expertise on UI/UX Design & Development, and astute understanding on user empathy, has helped us build a top-class product to be used by customers and internal teams. The easy to use interface, to add & edit content, helps Product Managers in a faster GTM ”

    `,
    className: "content"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: `Avnish Gupta, Associate Director of Products, Exotel`,
    className: "content"
  }
];

export default exotelApiCaseStudyData;
