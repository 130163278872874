// ---> sales <---
import priyankaImage from "../../../image/homePage/ourTeam/Sales/priyanka.jpg";
import alosiaImage from "../../../image/homePage/ourTeam/Sales/alosia.jpg";
import virajImage from "../../../image/homePage/ourTeam/Sales/viraj.jpg";
import rojaImage from "../../../image/homePage/ourTeam/Sales/roja.jpg";
import goldwinImage from "../../../image/homePage/ourTeam/Sales/goldwin.jpg";
import suvarnaImage from "../../../image/homePage/ourTeam/Sales/suvarna.jpg";
// ---> Design <---
import porusImage from "../../../image/homePage/ourTeam/Design/porus.jpg";
import naveenImage from "../../../image/homePage/ourTeam/Design/naveen.jpg";
import suneelImage from "../../../image/homePage/ourTeam/Design/sunil.jpg";
import likhitImage from "../../../image/homePage/ourTeam/Design/likhit.jpg";
import simranImage from "../../../image/homePage/ourTeam/Design/simran.jpg";
//  ---->Dev<---
import rahulImage from "../../../image/homePage/ourTeam/Dev/rahul.jpg";
import chetanImage from "../../../image/homePage/ourTeam/Dev/chetan.jpg";
import prakashImage from "../../../image/homePage/ourTeam/Dev/prakash.jpg";
import rohitImage from "../../../image/homePage/ourTeam/Dev/rohit.jpg";
import ankitImage from "../../../image/homePage/ourTeam/Dev/ankit.jpg";
import govindImage from "../../../image/homePage/ourTeam/Dev/govind.jpg";
import niranjanImage from "../../../image/homePage/ourTeam/Dev/niranjan.jpg";
import kartikeyaImage from "../../../image/homePage/ourTeam/Dev/kartikeya.jpg";

import mailLogo from "../../../image/socialLogos/teamMailIcon.svg";
import linkdinLogo from "../../../image/socialLogos/teamLinkedinIcon.svg";

export const mobileCarouselData = [
  {
    row1: [
      {
        name: "Porus Rathore",
        // designation: "CEO",
        details: (
          <>
            CEO
            <br></br>
            Leader | Serial Entrepreneur | Product Strategy Expert
          </>
        ),
        image: porusImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:porus.rathore@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/porusrathore/"
      },
      {
        name: "Priyanka Ganguly",
        details: (
          <>
            CFO
            <br></br>
            Business Development Expert
          </>
        ),
        image: priyankaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:priyanka.ganguly@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/priyankaganguly "
      }
    ],
    row2: [
      {
        name: "Naveen L",
        details: (
          <>
            CDO
            <br></br>
            Product Design Expert
          </>
        ),
        image: naveenImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:naveen.lingam@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/naveenlingam/"
      },
      {
        name: "Prakash Hemani",
        details: (
          <>
            CPO
            <br></br>
            Product Development Expert
          </>
        ),
        image: prakashImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:prakash.hemani@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/prakash-hemani-b629a588"
      }
    ]
  },
  {
    row1: [
      
      {
        name: "Alosia Rose",
        details: "Marketing Head",
        image: alosiaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:niranjan.kumar@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/niranjan-kumar-niru/"
      },
      {
        name: "Chiruvolu Roja ",
        details: "Sr. Business Development Manager",
        image: rojaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chiruvolu.roja@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/chiruvolu-roja-43b20324a"
      }
    ],
    row2: [
      {
        name: "Goldwin paul kavala ",
        details: "Sr. Business Development Manager",
        image: goldwinImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:goldwin.paul@deskala.in",
        // linkdinUrl: "https://www.linkedin.com/in/viraj-rathore-976173245"
      },
      {
        name: "Rahul Yadav",
        details: "Sr. Software Developer",
        image: rahulImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "rahul.y@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/rahulyadav96"
      },
      
    ]
  },
  {
    row1: [
     
      {
        name: "Chetan Malviya",
        details: "Sr. Software Developer",
        image: chetanImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chetan.malviya@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/devvrat02/"
      },
      {
        name: "Govind Kushwaha",
        details: "Sr. Software Developer",
        image: govindImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:govind.kushwaha@deskala.in ",
        linkdinUrl:
          "https://www.linkedin.com/in/govind-kumar-kushwaha-121433208/"
      },
    ],
    row2: [
      {
        name: "Rohit Singh Solanki",
        details: "Software Developer",
        image: rohitImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rohit.solanki@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/rohit-singh-solanki-924119147/"
      }
      // {
      //   name: "Ankit Gupta",
      //   details: "Sr. Software Developer",
      //   image: ankitImage,
      //   mailImg: mailLogo,
      //   linkdinImg: linkdinLogo,
      //   mailUrl: "mailto:ankit.gupta@deskala.in",
      //   linkdinUrl: "https://www.linkedin.com/in/iankitg/"
      // },
    
      
    ]
  },
  {
    row1: [
      {
        name: "Sunil CH",
        details: "UX/UI Designer",
        image: suneelImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:sunil.ch@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/suneel-kumar-234844220"
      },
    ],
    row2: [
     
      {
        name: "Likhit Gurrala",
        details: "UX/UI Designer",
        image: likhitImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:likhith.gurrala@deskala.in",
        linkdinUrl: "ttps://www.linkedin.com/in/likhith-gurrala-853ab8227"
      }
    ]
  }
];

export const teamCarouselData = [
  {
    row1: [
      {
        name: "Porus Rathore",
        // designation: "CEO",
        details: (
          <>
            CEO
            <br></br>
            Leader | Serial Entrepreneur | Product Strategy Expert
          </>
        ),
        image: porusImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:porus.rathore@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/porusrathore/"
      },
      {
        name: "Priyanka Ganguly",
        details: (
          <>
            CFO
            <br></br>
            Business Development Expert
          </>
        ),
        image: priyankaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:priyanka.ganguly@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/priyankaganguly "
      },
      {
        name: "Naveen L",
        details: (
          <>
            CDO
            <br></br>
            Product Design Expert
          </>
        ),
        image: naveenImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:naveen.lingam@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/naveenlingam/"
      },
      {
        name: "Prakash Hemani",
        details: (
          <>
            CPO
            <br></br>
            Product Development Expert
          </>
        ),
        image: prakashImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:prakash.hemani@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/prakash-hemani-b629a588"
      }
    ],
    row2: [
      // {
      //   name: "Kartikeya Agate",
      //   details: "Technical Director",
      //   image: kartikeyaImage,
      //   mailImg: mailLogo,
      //   linkdinImg: linkdinLogo,
      //   mailUrl: "mailto:kartikeya.agate@deskala.in",
      //   linkdinUrl: "https://www.linkedin.com/in/agatekartik/"
      // },
      {
        name: "Alosia Rose",
        details: "Marketing Head",
        image: alosiaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:niranjan.kumar@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/niranjan-kumar-niru/"
      },
      {
        name: "Chiruvolu Roja ",
        details: "Sr. Business Development Manager",
        image: rojaImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chiruvolu.roja@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/chiruvolu-roja-43b20324a"
      },

      {
        name: "Goldwin paul kavala ",
        details: "Sr. Business Development Manager",
        image: goldwinImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:goldwin.paul@deskala.in",
        // linkdinUrl: "https://www.linkedin.com/in/viraj-rathore-976173245"
      },
      {
        name: "Rahul Yadav",
        details: "Sr. Software Developer",
        image: rahulImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "rahul.y@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/rahulyadav96"
      },
    ]
  },
  {
    row1: [
      {
        name: "Chetan Malviya",
        details: "Sr. Software Developer",
        image: chetanImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:chetan.malviya@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/devvrat02/"
      },
      {
        name: "Govind Kushwaha",
        details: "Sr. Software Developer",
        image: govindImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:govind.kushwaha@deskala.in ",
        linkdinUrl:
          "https://www.linkedin.com/in/govind-kumar-kushwaha-121433208/"
      },
      {
        name: "Rohit Singh Solanki",
        details: "Software Developer",
        image: rohitImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:rohit.solanki@deskala.in",
        linkdinUrl: "https://www.linkedin.com/in/rohit-singh-solanki-924119147/"
      },
      // {
      //   name: "Ankit Gupta",
      //   details: "Sr. Software Developer",
      //   image: ankitImage,
      //   mailImg: mailLogo,
      //   linkdinImg: linkdinLogo,
      //   mailUrl: "mailto:ankit.gupta@deskala.in",
      //   linkdinUrl: "https://www.linkedin.com/in/iankitg/"
      // },
      // {
      //   name: "Niranjan Kumar ",
      //   details: "Sr. Software Developer",
      //   image: niranjanImage,
      //   mailImg: mailLogo,
      //   linkdinImg: linkdinLogo,
      //   mailUrl: "mailto:alosia.rose@deskala.in ",
      //   linkdinUrl: "https://www.linkedin.com/in/alosia-sheela-rose"
      // },
      
    ],
    row2: [

      // {
      //   name: "Viraj Rathore ",
      //   details: "Jr. Business Development Manager",
      //   image: virajImage,
      //   mailImg: mailLogo,
      //   linkdinImg: linkdinLogo,
      //   mailUrl: "mailto:likhith.gurrala@deskala.in",
      //   linkdinUrl: "https://www.linkedin.com/in/viraj-rathore-976173245"
      // },
      {
        name: "Sunil CH",
        details: "UX/UI Designer",
        image: suneelImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:sunil.ch@deskala.in ",
        linkdinUrl: "https://www.linkedin.com/in/suneel-kumar-234844220"
      },
      {
        name: "Likhit Gurrala",
        details: "UX/UI Designer",
        image: likhitImage,
        mailImg: mailLogo,
        linkdinImg: linkdinLogo,
        mailUrl: "mailto:likhith.gurrala@deskala.in",
        linkdinUrl: "ttps://www.linkedin.com/in/likhith-gurrala-853ab8227"
      }
    ]
  }
];
