import React from "react";
import classes from "./whoWeAre.module.css";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";
import CustomCard from "../CustomCard/CustomCard";

const WhoWeAre = ({ heading, heading2, description, cards }) => {
  return (
    <div className={classes.descriptionContainer}>
      <div className={classes.descriptionSubContainer}>
        <Heading heading={heading} />
        <Description description={description} />
        <Heading heading={heading2} />

        {cards ? (
          <div className={classes.customCardContainer}>
            <div className={`${classes.cardsContainer} `}>
              {cards.map((card, index) => {
                return (
                  <CustomCard
                    key={index}
                    cardImage={card.image}
                    cardTitle={card.title}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default WhoWeAre;
