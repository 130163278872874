import coverImage from "../../../image/CaseStudy/LeadsquaredMarketingCloudResearch/coverImage/coverImage.jpg";
import planningImage1 from "../../../image/CaseStudy/LeadsquaredMarketingCloudResearch/researchPlanning/image1.jpg";
import planningImage2 from "../../../image/CaseStudy/LeadsquaredMarketingCloudResearch/researchPlanning/image2.jpg";
import analysisImage from "../../../image/CaseStudy/LeadsquaredMarketingCloudResearch/analysis/analysisImage.jpg";
import discoveryImage from "../../../image/CaseStudy/LeadsquaredMarketingCloudResearch/discovery/discoveryImage.jpg";
import finalReportImage1 from "../../../image/CaseStudy/LeadsquaredMarketingCloudResearch/finalReport/image1.jpg";
import finalReportImage2 from "../../../image/CaseStudy/LeadsquaredMarketingCloudResearch/finalReport/image2.jpg";

const LeadSquaredMarketingData = [
  {
    componentType: "title",
    context: "Leadsquared Marketing Cloud Research",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    url: coverImage,
    className: "coverImg"
  },
  {
    componentType: "title",
    context: "Business Problem",
    className: "sub-heading"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `Leadsqaured is one of the leading CRM product in India. Since the current tool is focused on the Sales funnel handling.`,
      `The Product management team realised the potential of Marketing campaign that can help various companies along with their Lead Management tool.`,
      `One of the critical challenge that the customer wanted to handle was related to On-the-ground campaigns which is often used by various companies to acquire new customers.`
    ]
  },

  {
    componentType: "title",
    id: "requirements",
    context: "Research Preparation & Planning",
    className: "sub-heading"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `The Scope was re-drafted after the preliminary research around focusing on all campaign medium specially Digital and BTL.`,
      `Since the objective was to see how the current market was varying across domain it was critical for us to research customers across domains, to get the relevant user base who are taking such initiatives in companies. `,
      `We interviewed companies like Maruti, KIA, NIIT, Byjus, AON, Digit Insurance management people to conduct this study `
    ]
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [planningImage1, planningImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  },

  {
    componentType: "title",
    id: "solutions",
    context: "Research Discovery",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Since the project started with objective of addressing the offline campaign (BTL), it was discovered in the preliminary research that this is not the best market to target for doing campaigns. More often the kind of current execution challenge it was not feasible to generate enough revenue on the same.`,
    className: "content"
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: discoveryImage,
    className: "image"
  },
  {
    componentType: "title",
    id: "benifits",
    context: "Research Analysis",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Since this research primarily focused on getting the product direction. We conducted various analysis methods like affinity wall and Thematic data coding for deriving deeper insight in the problem space.`,
    className: "content"
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: analysisImage,
    className: "image"
  },

  {
    componentType: "title",
    id: "feedback",
    context: "Final Report",
    className: "sub-heading"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `Deskala was able to give a detailed report on how various companies have adopted different campaigning models based on their domain, and the marketing objective that needs to be attained.`,
      `Additionally we were also able to help client understand various complex business cases that can be helpful in solving their product related challenge and address open customer needs. `
    ]
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [finalReportImage1, finalReportImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  }
];

export default LeadSquaredMarketingData;
