import React from "react";
import classes from "./designThinkingWorkshop.module.css";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";

const DesignThinkingWorkshop = ({ heading, description, images }) => {
  return (
    <div className={classes.designThinkingContainer}>
      <div className={classes.designThinkingSubContainer}>
        <div className={classes.designThinkingDescriptionContainer}>
          <Heading heading={heading} />
          <Description description={description} />
        </div>

        <div className={classes.imageConatiner}>
          <div className={classes.thinkLeftContainer}>
            {images.map((item, index) => {
              if (item.container === "left") {
                return (
                  <div key={index} className={classes.img1Wrapper}>
                    <img src={item.img} alt={item.alt}  title={item.alt} />
                  </div>
                );
              }
              return null;
            })}
          </div>

          <div className={classes.thinkRightContainer}>
            {images.map((item, index) => {
              if (item.container === "right") {
                return (
                  <div key={index} className={classes.img2Wrapper}>
                    <img src={item.img} alt={item.alt} title={item.alt} />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignThinkingWorkshop;
