import React from "react";
import "./style.css";

const BrandImage = ({ url, className }) => {
  return (
    <div className=" text-start">
      <img src={url} alt="brand-img" className={className} title="brand Image" />
    </div>
  );
};

export default BrandImage;
