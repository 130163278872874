import React from "react";
import classes from "./designThinkingScroll.module.css";
import Heading from "../Heading/Heading";
import CustomImageCard from "../CustomImageCard/CustomImageCard";
import Description from "../Description/Description";
const DesignThinkingScroll = ({ heading, description, images }) => {
  return (
    <div className={classes.scrollContainer}>
      <div className={classes.designThinkingDescriptionContainer}>
        <Heading heading={heading} />
        <Description description={description} />
      </div>

      <div className={`${classes.scrollableImageContainer}`}>
        <div className={`${classes.scrollableImage}`}>
          <div className={classes.scrollaableImgSize}>
            {images.map((image, index) => {
              return (
                <img
                  key={index}
                  src={image.image}
                  alt={`think${index + 1}`}
                  title="images"
                  className={classes.scrollMobileImage}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignThinkingScroll;
