import React from "react";
import classes from "./brandDesignStrategy.module.css";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";
import CircularCard from "../CircularCards/CircularCard";

const BrandDesignStrategy = ({ heading, description, cards }) => {
  return (
    <div className={classes.brandContainer}>
      <div className={classes.brandSubContainer}>
        <div className={classes.brandDescriptionContainer}>
          <Heading heading={heading} />

          <Description description={description} />
        </div>

        <div className={classes.circularCardContainer}>
          {cards.map((card, index) => {
            return (
              <CircularCard
                key={index}
                title={card.title}
                backgroundColor={card.backgroundColor}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BrandDesignStrategy;
