import udemyLogoSvg from "../../../image/clientsLogos/udemyLogo.png";
import leadsSquareLogoSvg from "../../../image/clientsLogos/leadsSquareLogo.png";
import exotelLogoSvg from "../../../image/clientsLogos/exotelLogo.png";
// import paypalLogoSvg from "../../../image/clientsLogos/paypalLogo.svg";
import starLogoSvg from "../../../image/clientsLogos/starLogo.png";
import productsLeadershipLogoSvg from "../../../image/clientsLogos/productsLeadershipLogo.png";
import vChannelLogoSvg from "../../../image/clientsLogos/vChannelLogo.png";
import valgenesisLogoSvg from "../../../image/clientsLogos/valgenesisLogo.png";
import insideIIMLogoSvg from "../../../image/clientsLogos/insideIIMLogo.png";
import tallyLogoSvg from "../../../image/clientsLogos/tallylogo.png";
import vehitoLogoSvg from "../../../image/clientsLogos/vehitoLogo.png";
import stillsLogoSvg from "../../../image/clientsLogos/istillsLogo.png";
import collabnetLogoSvg from "../../../image/clientsLogos/collabnetLogo.png";
import yellowChalkLogoSvg from "../../../image/clientsLogos/yellowChalkLogo.png";
import karixLogoSvg from "../../../image/clientsLogos/karixLogo.png";

const companyLogos = [
  // { icons: () => <UdemyLogoSvg /> },
  // { icons: ()=><LeadsSquareLogoSvg/> },
  { icons: udemyLogoSvg },
  { icons: leadsSquareLogoSvg },
  { icons: exotelLogoSvg },
  // { icons: paypalLogoSvg },
  { icons: starLogoSvg },
  { icons: productsLeadershipLogoSvg },
  { icons: valgenesisLogoSvg },
  { icons: vChannelLogoSvg },
  { icons: insideIIMLogoSvg },
  { icons: tallyLogoSvg },
  { icons: vehitoLogoSvg },
  { icons: stillsLogoSvg },
  { icons: collabnetLogoSvg },
  { icons: yellowChalkLogoSvg },
  { icons: karixLogoSvg }
];

export default companyLogos;
