import React from "react";
import classes from "./uxDesign.module.css";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";
import CustomCard from "../CustomCard/CustomCard";

const UxDesign = ({ heading, description, cards }) => {
  return (
    <div className={classes.uxDesignContainer}>
      <div className={classes.uxDesignSubContainer}>
        <div className={classes.uxDescriptionContainer}>
          <Heading heading={heading} />
          <Description description={description} />
        </div>

        <div className={classes.customCardContainer}>
          <div className={`${classes.cardsContainer} `}>
            {cards.map((card, index) => {
              return (
                <CustomCard
                  key={index}
                  cardImage={card.image}
                  cardTitle={card.title}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UxDesign;
