import React from "react";
import classes from "./skalebotCaseStudy.module.css";
import SkalebotCaseStudyData from "../../../data/CaseStudiesData/SkalebotCaseStudy/SkalebotCaseStudData";
import Title from "../../../components/caseStudyComponents/Title/Title";
import CoverImage from "../../../components/caseStudyComponents/CoverImage/CoverImage";
import Description from "../../../components/caseStudyComponents/Description/Description";
import List from "../../../components/caseStudyComponents/List/List";
import CustomCarousel from "../../../components/caseStudyComponents/Carousel/CustomCarousel";
import BrandImage from "../../../components/caseStudyComponents/BrandImage/BrandImage";
import { useNavigate } from "react-router-dom";
import LeftSubContainer from "../../../components/caseStudyComponents/ResearchLeftContainer/LeftSubContainer";
import BenifitList from "../../../components/caseStudyComponents/BenifitList/BenifitList";
import ImageSlider from "../../../components/caseStudyComponents/ImageSlider/ImageSlider";
import LiList from "../../../components/caseStudyComponents/liList/LiList";
const SkalebotCaseStudy = () => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.leftSubContainer}>
        <LeftSubContainer />
      </div>

      <div className={classes.rightSubContainer}>
        {SkalebotCaseStudyData.map((item, index) => {
          switch (item.componentType) {
            case "title":
              return (
                <div key={index} id={item.id}>
                  <Title context={item.context} className={item.className} />{" "}
                </div>
              );
              break;
            case "CoverImage":
              return (
                <div key={index} id={item.id}>
                  <CoverImage url={item.url} className={item.className} />
                </div>
              );
              break;

            case "description":
              return (
                <div key={index} id={item.id}>
                  <Description
                    context={item.context}
                    className={item.className}
                  />
                </div>
              );
              break;

            case "list":
              return (
                <div key={index}>
                  <List
                    description={item.description}
                    listItem={item.listItem}
                    id={item.id}
                    className={item.className}
                  />{" "}
                </div>
              );
              break;
            case "liList":
              return (
                <div key={index}>
                  <LiList
                    description={item.description}
                    listItem={item.listItem}
                    id={item.id}
                    className={item.className}
                  />{" "}
                </div>
              );
              break;

            case "benifitList":
              return (
                <div key={index}>
                  <BenifitList
                    description={item.description}
                    listItem={item.listItem}
                    className={item.className}
                  />
                </div>
              );

            case "BrandImage":
              return (
                <div key={index}>
                  <BrandImage
                    url={item.url}
                    id={item.id}
                    className={item.className}
                  />
                </div>
              );
              break;

            case "carousel":
              return (
                <div key={index}>
                  <CustomCarousel
                    url={item.url}
                    id={item.id}
                    className={item.className}
                  />
                </div>
              );
              break;

            case "imageSlider":
              return (
                <div key={index}>
                  <ImageSlider url={item.url} />
                </div>
              );

            default:
              <></>;
              break;
          }
        })}
      </div>
    </div>
  );
};

export default SkalebotCaseStudy;
