import cardImage1 from "../../../image/servicesPages/Vr&3D/cards/image1.jpg";
import cardImage2 from "../../../image/servicesPages/Vr&3D/cards/image2.jpg";
import cardImage3 from "../../../image/servicesPages/Vr&3D/cards/image3.jpg";
import cardImage4 from "../../../image/servicesPages/Vr&3D/cards/image4.jpg";
import cardImage5 from "../../../image/servicesPages/Vr&3D/cards/image5.jpg";
import coverImage from "../../../image/servicesPages/Vr&3D/coverImage.jpg";
import workshopCardImage1 from "../../../image/servicesPages/Vr&3D/workshopCards/image1.jpg";
import workshopCardImage2 from "../../../image/servicesPages/Vr&3D/workshopCards/image2.jpg";
import workshopCardImage3 from "../../../image/servicesPages/Vr&3D/workshopCards/image3.jpg";
import workshopCardImage4 from "../../../image/servicesPages/Vr&3D/workshopCards/image4.jpg";
import workshopCardImage5 from "../../../image/servicesPages/Vr&3D/workshopCards/image5.jpg";
import workshopCardImage6 from "../../../image/servicesPages/Vr&3D/workshopCards/image6.jpg";

const itemslistnav = [
  { label: "Research", path: "reasearch", url: "/services/research" },
  { label: "Design", path: "skalebot", url: "/services/design" },
  {
    label: "Development",
    path: "development",
    url: "/services/development"
  },
  { label: "AR&VR", path: "AR&VR", url: "/services/AR&VR" }
];

const VRand3DData = [
  {
    componentType: "introduction",
    itemslist: itemslistnav,
    heading1: "Exploring the Boundless ",
    heading2: "Realms of ",
    span2: "Virtual Reality",
    img: coverImage,
    className: [
      "introContainer",
      "leftIntro",
      "innerNav",
      "innerItem",
      "title",
      "heading",
      "rightIntro"
    ]
  },
  {
    componentType: "whoWeAre",
    description: `At Deskala, we are always looking to break new ground. We thrive on innovation, constantly seeking new ways to push the boundaries of what’s
    possible. We’re currently diving headfirst into the fascinating world of VR ( Virtual Reality ). As technology evolves, as do we, and we’re confident
    that the future is all about immersive experiences. That’s why we’re committed to helping you complete the power of VR`
  },
  {
    componentType: "whatWeDo",
    heading: "How VR Can Transform",
    description: `Virtual Reality possesses the transformative potential to revolutionize a multitude of industries and applications. Here are some examples of how
    VR can bring about significant changes`,
    cards: [
      {
        cardImage: cardImage1,
        cardTitle: "Product Design and Prototyping"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: cardImage2,
        cardTitle: "Educational VR"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: cardImage3,
        cardTitle: "Immersive VR Gaming"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: cardImage4,
        cardTitle: "VR in Architecture and Design"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: cardImage5,
        cardTitle: "VR Training"
        // cardDescription: " Lorem Ipsum is not simply random text."
      }
    ]
  },
  {
    componentType: "workshopCards",
    heading: "Explore our VR world",
    description: `Discover the boundless possibilities of Virtual Reality. From exploring dream homes to virtually shopping of your favorite items, we have got it all. Your adventure begins here!`,
    cards: [
      {
        cardImage: workshopCardImage1,
        publishBy: "Youtube • 2 months ago",
        cardTitle: "Shop in Style: Explore the Virtual Shopping Experience!",
        url: "https://www.youtube.com/watch?v=7YQGuLeGY9s"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: workshopCardImage2,
        publishBy: "Youtube • 2 months ago",
        cardTitle: "VR Basketball: Slam Dunk into Virtual Excitement!",
        url: "https://www.youtube.com/watch?v=x8_ia8VTgTw"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: workshopCardImage3,
        publishBy: "Youtube • 2 months ago",
        cardTitle: "Next-Level Entertainment: VR Music & Video Players!",
        url: "https://www.youtube.com/watch?v=oKST73gaLHE"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: workshopCardImage4,
        publishBy: "Youtube • 2 months ago",
        cardTitle:
          "Exploring the VR Game City: Your Gateway to Infinite Adventures!",
        url: "https://www.youtube.com/watch?v=nyrBM_2mHPY"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: workshopCardImage5,
        publishBy: "Youtube • 2 months ago",
        cardTitle: "Drum Studio VR: Your Rhythm in Virtual Reality!",
        url: "https://www.youtube.com/watch?v=CNjVKDzydVA"
        // cardDescription: " Lorem Ipsum is not simply random text."
      },
      {
        cardImage: workshopCardImage6,
        publishBy: "Youtube • 2 months ago",
        cardTitle: "VR Squash Game: Dive into the Intense Action!",
        url: "https://www.youtube.com/watch?v=f_lfAHoLAEE"
        // cardDescription: " Lorem Ipsum is not simply random text."
      }
    ]
  }
  // {
  //   componentType: "whatWeThink",
  //   heading: "What We Think",
  //   images: [
  //     {
  //       image: think1Svg,
  //       alt: "think1",
  //       container: "left" // Specify whether it should go in the left or right container
  //     },
  //     {
  //       image: think2Svg,
  //       alt: "think2",
  //       container: "left"
  //     },
  //     {
  //       image: think3Svg,
  //       alt: "think3",
  //       container: "right"
  //     },
  //     {
  //       image: think4Svg,
  //       alt: "think4",
  //       container: "right"
  //     }
  //   ]
  // },
  // {
  //   componentType: "scrollableImage",
  //   heading: "What We Think",
  //   images: [
  //     { image: think1Svg },
  //     { image: think1Svg },
  //     { image: think1Svg },
  //     { image: think1Svg }
  //   ]
  // }
];

export default VRand3DData;

// export const WhatWedoData = [
//   {
//     img: cardSvg,
//     title: "Zero based Transformation",
//     description: "Lorem Ipsum is not simply random text."
//   },
//   {
//     img: cardSvg,
//     title: "Zero based Transformation",
//     description: "Lorem Ipsum is not simply random text."
//   },
//   {
//     img: cardSvg,
//     title: "Zero based Transformation",
//     description: "Lorem Ipsum is not simply random text."
//   }
// ];

// export const WhoWeAredata = [
//   {
//     title: "Who We Are",

//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minim ve niam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in   reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur. Excepteur sint occaecat cupidatat non proident, sunt inculpa qui officia deserunt mollit anim id est labo esse cillum"
//   }
// ];

// export const WhatWeThinkData = [
//   {
//     img: think1Svg,
//     alt: "think1",
//     container: "left" // Specify whether it should go in the left or right container
//   },
//   {
//     img: think2Svg,
//     alt: "think2",
//     container: "left"
//   },
//   {
//     img: think3Svg,
//     alt: "think3",
//     container: "right"
//   },
//   {
//     img: think4Svg,
//     alt: "think4",
//     container: "right"
//   }
//   //   // Add more objects for additional images
// ];

// export const WhatWeThinkScrollimagesData = [
//   { image: think1Svg },
//   { image: think1Svg },
//   { image: think1Svg },
//   { image: think1Svg }
// ];
