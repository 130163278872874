import testimonialsimgBgSvg from "../../../image/homePage/testimonials/imageBackground.jpg";
import testimonialsimgBgSvg2 from "../../../image/homePage/testimonials/imageBackground2.jpg";
import { ReactComponent as ExotelLogoSvg } from "../../../image/homePage/testimonials/exotelIcon.svg";
import exotelLogoSvg from "../../../image/homePage/testimonials/exotelIcon.svg";

const testimonialData = [
  {
    testimonial:
      "Yellowchalk studio is a creative UI/UX design house that provides it's client an edge with its solution creation. Deskala acted as our UX expert partner for multiple engagement. From dealing with customer who are in the top 10 in the world, to customers working in AI based system. Deskala was always committed to take up challenges that may be difficult problems, to achiving timely delivery and supporting UX evangelizing of the customer.",
    person: "Rajiv",
    position: "CEO",
    company: "Yellowchalk"
  },
  {
    testimonial: `On behalf of Exotel, I would like to acknowledge the great work done by Deskala in providing us an easy-to-use CMS for our Developer Portal. The quality of work and timely deliverables have made it possible for us to roll it out before the year ends. Really appreciate the diligent approach taken in the product design and issue resolution. Special mention for Prakash on his prompt responsiveness and showing maturity in owning the project.`,
    person: "Avnish Gupta",
    position: "Products",
    company: `Exotel Techcom Private Limited`
  },
  {
    testimonial: `Being in B2B space UX designing was indeed a tougher and challenging job for us. Perfect user interface and ease of usability are the main mantras for a successful mobile application. Deskala helped us in providing top class design and workflow that enabled our customers to upgrade themselves on a digital platform without any hassles. Thanks to the entire Deskala team to making Vehito as one of the best designed application in auto sector.`,
    person: "Sreenivas Paluvuri",
    position: "CEO",
    company: "Vehito"
  },
  {
    testimonial: `Deskala team did a commendable job in designing our website. They understood our domain of Photography rentals and user community to come up with a design that is elegant and easy for customers to use. Deskala also did our logo and branding which helped to establish our brand in the rental market. We would recommend Deskala for any product design consulting.`,
    person: "Prabhakaran S",
    position: "CEO",
    company: "iStills"
  },
  {
    testimonial: `The Deskala team has done amazing work in building an API Console for Exotel. Their expertise on UI/UX Design &amp; Development, and astute understanding on user empathy, has helped us build a top-class product to be used by customers and internal teams. The easy to use interface, to add &amp; edit content, helps Product Managers in a faster GTM.`,
    person: "Avnish Gupta",
    position: "Associate Director of Products",
    company: `Exotel Techcom Private Limited`
  }
];

// const testimonialData = [
//   // {
//   //   items: [
//   //     {
//   //       grid1: [
//   //         {
//   //           subgrp1: [
//   //             {
//   //               id: 1,
//   //               backgroundImage: testimonialsimgBgSvg,
//   //               companyLogo: () => <ExotelLogoSvg />,
//   //               height: "20vh",
//   //               backgroundSize: "cover",
//   //               backgroundRepeat: "no-repeat",
//   //               backgroundPosition: "center",
//   //               name: "Cody Fisher",
//   //               position: "CEO",
//   //               company: "Exotel",
//   //               content:
//   //                 "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//   //             },
//   //             {
//   //               id: 2,
//   //               backgroundImage: testimonialsimgBgSvg,
//   //               companyLogo: () => <ExotelLogoSvg />,
//   //               height: "20vh",
//   //               backgroundSize: "cover",
//   //               backgroundRepeat: "no-repeat",
//   //               backgroundPosition: "center",
//   //               name: "Cody Fisher",
//   //               position: "CEO",
//   //               company: "Exotel",
//   //               content:
//   //                 "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//   //             }
//   //           ]
//   //         },
//   //         {
//   //           subgrp2: [
//   //             {
//   //               id: 3,
//   //               backgroundImage: testimonialsimgBgSvg,
//   //               companyLogo: () => <ExotelLogoSvg />,
//   //               height: "41vh",
//   //               backgroundSize: "cover",
//   //               backgroundRepeat: "no-repeat",
//   //               backgroundPosition: "center",
//   //               name: "Cody Fisher",
//   //               position: "CEO",
//   //               company: "Exotel",
//   //               content:
//   //                 "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//   //             }
//   //           ]
//   //         },
//   //         {
//   //           subgrp3: [
//   //             {
//   //               id: 4,
//   //               backgroundImage: testimonialsimgBgSvg,
//   //               companyLogo: () => <ExotelLogoSvg />,
//   //               height: "20vh",
//   //               backgroundSize: "cover",
//   //               backgroundRepeat: "no-repeat",
//   //               backgroundPosition: "center",
//   //               name: "Cody Fisher",
//   //               position: "CEO",
//   //               company: "Exotel",
//   //               content:
//   //                 "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//   //             },
//   //             {
//   //               id: 5,
//   //               backgroundImage: testimonialsimgBgSvg,
//   //               companyLogo: () => <ExotelLogoSvg />,
//   //               height: "20vh",
//   //               backgroundSize: "cover",
//   //               backgroundRepeat: "no-repeat",
//   //               backgroundPosition: "center",
//   //               name: "Cody Fisher",
//   //               position: "CEO",
//   //               company: "Exotel",
//   //               content:
//   //                 "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//   //             }
//   //           ]
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       grid2: [
//   //         {
//   //           id: 6,
//   //           backgroundImage: testimonialsimgBgSvg,
//   //           companyLogo: () => <ExotelLogoSvg />,
//   //           height: "25vh",
//   //           // width: "20vw",
//   //           backgroundSize: "cover",
//   //           backgroundRepeat: "no-repeat",
//   //           backgroundPosition: "center",
//   //           name: "Cody Fisher",
//   //           position: "CEO",
//   //           company: "Exotel",
//   //           content:
//   //             "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//   //         },
//   //         {
//   //           id: 7,
//   //           backgroundImage: testimonialsimgBgSvg,
//   //           companyLogo: () => <ExotelLogoSvg />,
//   //           height: "25vh",
//   //           // width: "20vw",
//   //           backgroundSize: "cover",
//   //           backgroundRepeat: "no-repeat",
//   //           backgroundPosition: "center",

//   //           name: "Cody Fisher",
//   //           position: "CEO",
//   //           company: "Exotel",
//   //           content:
//   //             "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//   //         }
//   //       ]
//   //     }
//   //   ]
//   // }

//   {
//     items: [
//       {
//         grid1: [
//           {
//             subgrp1: [
//               {
//                 id: 1,
//                 backgroundImage: testimonialsimgBgSvg,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               },
//               {
//                 id: 2,
//                 backgroundImage: testimonialsimgBgSvg,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               }
//             ]
//           },
//           {
//             subgrp2: [
//               {
//                 id: 3,
//                 backgroundImage: testimonialsimgBgSvg2,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "41vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               }
//             ]
//           },
//           {
//             subgrp3: [
//               {
//                 id: 4,
//                 backgroundImage: testimonialsimgBgSvg,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               },
//               {
//                 id: 5,
//                 backgroundImage: testimonialsimgBgSvg,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               }
//             ]
//           }
//         ]
//       },
//       {
//         grid2: [
//           {
//             id: 6,
//             backgroundImage: testimonialsimgBgSvg,
//             // companyLogo: () => <ExotelLogoSvg />,
//             companyLogo: exotelLogoSvg,
//             height: "25vh",
//             // width: "20vw",
//             backgroundSize: "cover",
//             backgroundRepeat: "no-repeat",
//             backgroundPosition: "center",
//             name: "Cody Fisher",
//             position: "CEO",
//             company: "Exotel",
//             content:
//               "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//           },
//           {
//             id: 7,
//             backgroundImage: testimonialsimgBgSvg,
//             // companyLogo: () => <ExotelLogoSvg />,
//             companyLogo: exotelLogoSvg,
//             height: "25vh",
//             // width: "20vw",
//             backgroundSize: "cover",
//             backgroundRepeat: "no-repeat",
//             backgroundPosition: "center",

//             name: "Cody Fisher",
//             position: "CEO",
//             company: "Exotel",
//             content:
//               "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//           }
//         ]
//       }
//     ]
//   },
//   {
//     items: [
//       {
//         grid1: [
//           {
//             subgrp1: [
//               {
//                 id: 1,
//                 backgroundImage: testimonialsimgBgSvg2,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               },
//               {
//                 id: 2,
//                 backgroundImage: testimonialsimgBgSvg2,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               }
//             ]
//           },
//           {
//             subgrp2: [
//               {
//                 id: 3,
//                 backgroundImage: testimonialsimgBgSvg,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "41vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               }
//             ]
//           },
//           {
//             subgrp3: [
//               {
//                 id: 4,
//                 backgroundImage: testimonialsimgBgSvg2,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               },
//               {
//                 id: 5,
//                 backgroundImage: testimonialsimgBgSvg2,
//                 // companyLogo: () => <ExotelLogoSvg />,
//                 companyLogo: exotelLogoSvg,
//                 height: "20vh",
//                 backgroundSize: "cover",
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 name: "Cody Fisher",
//                 position: "CEO",
//                 company: "Exotel",
//                 content:
//                   "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//               }
//             ]
//           }
//         ]
//       },
//       {
//         grid2: [
//           {
//             id: 6,
//             backgroundImage: testimonialsimgBgSvg2,
//             // companyLogo: () => <ExotelLogoSvg />,
//             companyLogo: exotelLogoSvg,
//             height: "25vh",
//             // width: "20vw",
//             backgroundSize: "cover",
//             backgroundRepeat: "no-repeat",
//             backgroundPosition: "center",
//             name: "Cody Fisher",
//             position: "CEO",
//             company: "Exotel",
//             content:
//               "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//           },
//           {
//             id: 7,
//             backgroundImage: testimonialsimgBgSvg,
//             // companyLogo: () => <ExotelLogoSvg />,
//             companyLogo: exotelLogoSvg,
//             height: "25vh",
//             // width: "20vw",
//             backgroundSize: "cover",
//             backgroundRepeat: "no-repeat",
//             backgroundPosition: "center",

//             name: "Cody Fisher",
//             position: "CEO",
//             company: "Exotel",
//             content:
//               "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its It is a long established."
//           }
//         ]
//       }
//     ]
//   }

//   // Add more testimonials as needed
// ];

export default testimonialData;
