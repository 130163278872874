import React, { useState, useEffect } from "react";
import classes from "./customIntroduction.module.css";

import InnerNav from "../../InnerNav/InnerNav";

const CustomIntroduction = ({
  title,
  heading1,
  heading2,
  subHeading,
  spanTitle1,
  spanTitle2,
  itemslist,
  img,
  className,
  style = {}
}) => {
  return (
    <div className={classes.introContainer}>
      <div className={classes.leftIntro}>
        <div className={classes.innerNav}>
          <InnerNav itemslist={itemslist} />
        </div>
        <div className={classes.innerItem}>
          {spanTitle1 ? (
            <h3 className={classes.spanTitle}>{spanTitle1}</h3>
          ) : (
            ""
          )}
           <h2 className={classes.heading}>{heading1} </h2>
           <h2 className={classes.heading}>{heading2} </h2>
          {spanTitle2 ? (
            <h3 className={classes.spanTitle}>{spanTitle2}</h3>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={classes.rightIntro}>
        <img src={img} alt="cover-img" title="Cover images" className={classes.imageClass} />
      </div>
    </div>
  );
};

export default CustomIntroduction;
