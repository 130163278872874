import React, { useState } from "react";
import headingBottomSvg from "../../image/homePage/headingBottom.svg";
import products from "../../data/HomePageData/productsData/products";
import userSvg from "../../image/homePage/ourProducts/users.jpg";
import "./style.css";
import { useNavigate } from "react-router-dom";

const OurProducts = () => {
  const navigate = useNavigate();

  const handleProductPage = (product, index) => {
    // Check if it's one of the first two products
    if (index < 2) {
      navigate(product);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div id="products" className="p-3 p-md-5 bg-white \">
      <div className="text-center">
         <h2 className="font-bold mb-0 title-text text-black">Our Products </h2>
        <img src={headingBottomSvg} alt="ourServicesBottom" title="bottom line" />
      </div>
      <div className="scrollable-container  ">
        <div className="row p-3 p-md-5 justify-content-start justify-content-xl-center ">
          {products.map((product, index) => (
            <div
              key={index}
              className={`col-xl-3 col-lg-4 col-md-6 col-sm-6 col-10 mb-4  ${
                index >= 2 ? "nonClickable " : ""
              }`}
              onClick={() => handleProductPage(product.url, index)}
            >
              <div className="card custom-card ">
                <div className=" card-body custom-card d-flex flex-column  py-5">
                  <div className="d-flex justify-content-between py-2">
                    {product.icon ? (
                      <img
                        src={product.icon}
                        alt={product.title}
                        title={product.title}
                        className="mb-4 "
                      />
                    ) : (
                      <div className="placeholder" />
                    )}
                    <div className="d-flex">
                      <p className="subtext">{product.users}</p>
                    </div>
                  </div>
                  <h2 className="products-text-base">{product.title}</h2>

                  {/* <div className=""> */}
                  <p className="textContent text-wrap">{product.description}</p>
                  {/* </div> */}
                  <div className="py-3 text-start">
                    <button
                      type="button"
                      className="btn btn-dark text-black text-start"
                    >
                      {product.buttonLabel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
