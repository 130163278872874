import React from "react";
import classes from "./whatWeDo.module.css";
import CustomCard from "../CustomCard/CustomCard";
import CardTitle from "../CardTitle/CardTitle";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";

const WhatWeDo = ({
  heading,
  description,
  cards,
  cardTitle,
  cardImage,
  cardDescription
}) => {
  return (
    <div className={classes.ourWorkContainer}>
      <div className={classes.ourWorkSubContainer}>
        <Heading heading={heading} />
        <Description description={description} />
        <div className={`${classes.scrollableContainer}`}>
          <div className={`${classes.cardsContainer} `}>
            {cards.map((item, index) => {
              return (
                <CustomCard
                  key={index}
                  cardImage={item.cardImage}
                  cardTitle={item.cardTitle}
                  cardDescription={item.cardDescription}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
