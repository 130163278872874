import React from "react";
import "./style.css";
import arrowSvg from "../../../image/benifitIcon.svg";

const BenifitList = ({ description, listItem, className, style = {} }) => {
  return (
    <div>
      <div className={className} style={style}>
        {description ? (
          <p style={{ margin: 0 }} className={className[2]}>
            {description}
          </p>
        ) : (
          ""
        )}
        {listItem.map((item, index) => (
          <div key={index} className={className[0]}>
            <img src={arrowSvg} alt="arrow" title="arrow Icon" className={className[1]} />
            <p className={className[2]}>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenifitList;
