import cardSvg from "../../../image/servicesPages/Vr&3D/cards/card1.jpg";

import designCoverSvg from "../../../image/servicesPages/Design/designCover.jpg";
import cardImage1 from "../../../image/servicesPages/Design/cardImage/image1.jpg";
import cardImage2 from "../../../image/servicesPages/Design/cardImage/image2.jpg";
import cardImage3 from "../../../image/servicesPages/Design/cardImage/image3.jpg";
import cardImage4 from "../../../image/servicesPages/Design/cardImage/image4.jpg";
import cardImage5 from "../../../image/servicesPages/Design/cardImage/image5.jpg";
import cardImage6 from "../../../image/servicesPages/Design/cardImage/image6.jpg";
import zigZagLeftImage1 from "../../../image/servicesPages/Design/zigZagCardsImages/left1.jpg";
import zigZagLeftImage2 from "../../../image/servicesPages/Design/zigZagCardsImages/left2.jpg";
import zigZagRightImage1 from "../../../image/servicesPages/Design/zigZagCardsImages/right1.jpg";
import zigZagRightImage2 from "../../../image/servicesPages/Design/zigZagCardsImages/right2.jpg";

const itemslistnav = [
  { label: "Research", path: "research", url: "/services/research" },
  { label: "Design", path: "design", url: "/services/design" },
  {
    label: "Development",
    path: "development",
    url: "/services/development"
  },
  { label: "AR&VR", path: "AR&VR", url: "/services/AR&VR" }
];

const DesignData = [
  {
    componentType: "introduction",
    itemslist: itemslistnav,
    heading1: "Your Vision,  ",
    heading2: " Our Expertise, ",
    span2: " We Bring Ideas to Life",

    img: designCoverSvg,
    className: [
      "introContainer",
      "leftIntro",
      "innerNav",
      "innerItem",
      "title",
      "heading",
      "rightIntro"
    ]
  },
  {
    componentType: "brandDesignStrategy",
    heading: "  Brand Design Strategy",
    description: `Your brand is more than just a logo or a name; we understand that a strong brand identity is the cornerstone of success for any business.
      That's why we're here to partner with you on your Brand Journey. We go beyond just providing excellent services; we help you build a 
      brand that resonates with your audience, fostering awareness and unwavering loyalty.`,
    circularCards: [
      { title: `Brand Strategy`, backgroundColor: `#3D5860` },
      { title: `Brand Survey`, backgroundColor: `#0C1117` },
      { title: `Competitive Research `, backgroundColor: `#3D5860` },
      { title: `Branding Guidelines `, backgroundColor: `#0C1117` },
      { title: `Stationery `, backgroundColor: `#3D5860` }
    ]
  },
  {
    componentType: "uxDesign",
    heading: "UX Design Consulting",
    description: `Bringing your innovative ideas to life requires not just creativity but also a mastery of execution. At Deskala, we specialize in providing top-tier
      consulting services to help you craft the finest User Experiences (UX). We guide you through every step of the UX lifecycle integration, ensuring
      excellence at every turn.`,
    cards: [
      { image: cardImage1, title: `Stakeholder/Business Research` },
      { image: cardImage2, title: `User Research & Idea Validation` },
      { image: cardImage3, title: `User profile & persona definition` },
      { image: cardImage4, title: `Product Scenario/Workflow Definition` },
      { image: cardImage5, title: `Concept Design & UI Prototyping` },
      { image: cardImage6, title: `Usability Testing` }
    ]
  },
  {
    componentType: "designThinkingWorkshop",
    heading: "Design Thinking Workshop",
    description: `In today's fiercely competitive era, staying at the forefront of your industry requires innovative thinking.Deskala specializes in hosting dynamic 
      Design Thinking seminars and two-day workshops, aimed at looking at the different ways of enhancing the Innovation and Creativity within teams.
      We design customized Design thinking exercises and activities depending on the requirements and the findingsfrom a Design Research Exercise.`,
    images: [
      {
        img: zigZagLeftImage1,
        alt: "think1",
        container: "left" // Specify whether it should go in the left or right container
      },
      {
        img: zigZagLeftImage2,
        alt: "think2",
        container: "left"
      },
      {
        img: zigZagRightImage1,
        alt: "think3",
        container: "right"
      },
      {
        img: zigZagRightImage2,
        alt: "think4",
        container: "right"
      }
    ]
  },
  {
    componentType: "designThinkingScroll",
    heading: "Design Thinking Workshop",
    description: `In today's fiercely competitive era, staying at the forefront of your industry requires innovative thinking.Deskala specializes in hosting dynamic 
      Design Thinking seminars and two-day workshops, aimed at looking at the different ways of enhancing the Innovation and Creativity within teams.
      We design customized Design thinking exercises and activities depending on the requirements and the findingsfrom a Design Research Exercise.`,
    images: [
      { image: zigZagLeftImage1 },
      { image: zigZagLeftImage2 },
      { image: zigZagRightImage1 },
      { image: zigZagRightImage2 }
    ]
  }
];

export default DesignData;
