import React from "react";
import "./style.css";
import arrowSvg from "../../../image/requirementArrow.svg";

const List = ({ description, listItem, className, style = {} }) => {
  return (
    <div>
      <div className={className} style={style}>
        {description ? <p className={className[2]}>{description}</p> : ""}
        {listItem.map((item, index) => (
          <div key={index} className={className[0]}>
            <img src={arrowSvg} title="Arrow Icon" alt="arrow" className={className[1]} />
            <p className={className[2]}>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;
