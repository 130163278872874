import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Home from "./view/Home";
import { Helmet } from "react-helmet";
import { HelmetProvider } from 'react-helmet-async';
import Header from "./components/Header";
import Skalebot from "./view/ProductPages/Skalebot/Skalebot";
import Skalework from "./view/ProductPages/SkaleWork/SkaleWork";
import Skalebill from "./view/ProductPages/SkaleBill/SkaleBill";
import SkaleInventory from "./view/ProductPages/StockInventory/StockInventory";
import UdemyResearch from "./view/CaseStudies/UdemyResearch/UdemyResearch";
import ExotelResearch from "./view/CaseStudies/ExotelResearch/ExotelResearch";
import ExotelApiConsole from "./view/CaseStudies/ExotelApiConsole/ExotelApiConsole";
import VehitoResearch from "./view/CaseStudies/VehitoResearch/VehitoResearch";
import IstillsResearch from "./view/CaseStudies/IstillsResearch/IstillsResearch";
import VR from "./view/ServicesPages/VR/VR";
import Development from "./view/ServicesPages/Development/Development";
import Design from "./view/ServicesPages/Design/Design";
import Research from "./view/ServicesPages/Research/Research";
import LeadSquaredMarketing from "./view/CaseStudies/LeadSquaredMaketing/LeadSquaredMarketing";
import LeadSquaredCustomer from "./view/CaseStudies/LeadSquaredCustomer/LeadSquaredCustomer";
import SkalebotCaseStudy from "./view/CaseStudies/SkalebotCaseStudy/SkalebotCaseStudy";
import ChatbotModal from "./components/Chatbot/ChatbotModal";
import chatBotIcon from "./image/homePage/chatBotIcon.svg";
import PageNotFound from "./components/PageNotFound";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const handleChatbotClick = () => {
    setIsModalOpen(true);
  };
  const is404Page = ![
    "/",
    "/products/skalebot",
    "/products/skalework",
    "/products/skalebill",
    "/products/stockinventory",
    "/services/research",
    "/services/design",
    "/services/development",
    "/services/AR&VR",
    "/caseStudy/skalebot",
    "/caseStudy/udemy",
    "/caseStudy/exotel",
    "/caseStudy/exotelApiConsole",
    "/caseStudy/vehito",
    "/caseStudy/istills",
    "/caseStudy/leadSquaredMarketing",
    "/caseStudy/leadSquaredCustomer"
  ].includes(location.pathname);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/products/skalebot" element={<Skalebot />} />
          <Route path="/products/skalework" element={<Skalework />} />
          <Route path="/products/skalebill" element={<Skalebill />} />
          <Route path="/products/stockinventory" element={<SkaleInventory />} />
          <Route path="/services/research" element={<Research />} />
          <Route path="/services/design" element={<Design />} />
          <Route path="/services/development" element={<Development />} />
          <Route path="/services/AR&VR" element={<VR />} />
          <Route path="/caseStudy/skalebot" element={<SkalebotCaseStudy />} />
          <Route path="/caseStudy/udemy" element={<UdemyResearch />} />
          <Route path="/caseStudy/exotel" element={<ExotelResearch />} />
          <Route
            path="/caseStudy/exotelApiConsole"
            element={<ExotelApiConsole />}
          />
          <Route path="/caseStudy/vehito" element={<VehitoResearch />} />
          <Route path="/caseStudy/istills" element={<IstillsResearch />} />
          <Route
            path="/caseStudy/leadSquaredMarketing"
            element={<LeadSquaredMarketing />}
          />
          <Route
            path="/caseStudy/leadSquaredCustomer"
            element={<LeadSquaredCustomer />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {!is404Page && (
        <div className="chatbot">
          <img
            src={chatBotIcon}
            alt="chatBotIcon"
            title="chat bot icon"
            onClick={handleChatbotClick}
          />
        </div>
      )}
      {isModalOpen && (
        <div className="chatbot-modal-container">
          <ChatbotModal
            show={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      )}
    </div>
  );
}

export default App;
