import React, { useState, useEffect } from "react";
import "./style.css";

const CoverImage = ({ url, className, style = {} }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // Your image loading logic here
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImagesLoaded(true);
    };
    img.src = url;
  }, [url]);
  return (
    <div className=" text-start">
      {imagesLoaded ? (
        <img src={url} title="Cover Image" alt="cover-img" className={className} />
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
};

export default CoverImage;
