import React, { useState, useEffect, useMemo } from "react";
import classes from "./header.module.css";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import deskalaLogo from "../../image/homePage/deskalaHeaderIcon.svg";

import { ReactComponent as HamburgerSvg } from "../../image/homePage/hamburger.svg";

const MenuItem = ({ label, id, onClick, isActive }) => (
  <Button
    label={label}
    link
    className={`${classes.buttonTitle} ${isActive && id ? classes.active : ""}
    `}
    onClick={() => {
      onClick(id);
    }}
  />
);

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const onClose = () => {
    setHamburgerMenuOpen(false);
  };

  const toggleHamburgerMenu = () => {
    setHamburgerMenuOpen(!isHamburgerMenuOpen);
  };
  const handleClickScroll = (idName) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${classes.header}`);
        const headerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - headerHeight;

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };

  const menuItems = useMemo(
    () => [
      { label: "Home", id: "home" },
      { label: "Services", id: "services" },
      { label: "Products", id: "products" },
      { label: "Recent Work", id: "caseStudy" },
      { label: "Enquiry", id: "enquiry" },
      { label: "Core Team", id: "team" }
    ],
    []
  );

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/") {
      setActiveMenuItem("home");
    } else {
      // Find the matching menu item based on the URL segment.
      const matchedMenuItem = menuItems.find((item) =>
        pathname.startsWith(`/${item.id}`)
      );

      if (matchedMenuItem) {
        setActiveMenuItem(matchedMenuItem.id);
      } else {
        setActiveMenuItem(null);
      }
    }
  }, [location.pathname, menuItems]);

  return (
    <div className={classes.header}>
      <img
        src={deskalaLogo}
        alt="deskala-logo"
        title="deskala-logo"
        onClick={() => handleClickScroll("home")}
      />

      <div className={classes.navbarMenu}>
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            label={menuItem.label}
            id={menuItem.id}
            onClick={() => handleClickScroll(menuItem.id)}
            isActive={menuItem.id === activeMenuItem}
          />
        ))}
      </div>
      <HamburgerSvg
        onClick={toggleHamburgerMenu}
        className={classes.hamburger}
      />

      {isHamburgerMenuOpen && (
        <div className={`${classes.hamburgerMenu} ${classes.open}`}>
          <div className={classes.hamburgerMenuContent}>
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.id}
                label={menuItem.label}
                id={menuItem.id}
                onClick={() => {
                  handleClickScroll(menuItem.id);
                  onClose();
                }}
                isActive={menuItem.id === activeMenuItem}
              />
            ))}

            <div className={classes.cancelIcon} onClick={toggleHamburgerMenu}>
              <h2>X</h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
