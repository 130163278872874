import React from "react";
import classes from "./research.module.css";
import ResearchData from "../../../data/ServicesData/ResearchData/ResearchData";
import CustomIntroduction from "../../../components/ServicesComponent/Introduction/CustomIntroduction";
import WhoWeAre from "../../../components/ServicesComponent/VRWhoWeAre/WhoWeAre";
import UxDesign from "../../../components/ServicesComponent/DesignUxDesign/UxDesign";
import { Helmet } from 'react-helmet-async';

const Research = () => {
  return (
    <div className={` ${classes.mainContainer}`}>
       <Helmet>
        <title>Deskala- research </title>
        <link rel="canonical" href="https://www.deskala.in/services/research" />
        <meta name="description" content="This is a Deskala website research page" />
         <meta property="og:title" content=">Deskala- Research" />
        <meta property="og:description" content="At Deskala, we excel in innovation-driven User Research that serves as the cornerstone for creating, developing" />
        <meta property="og:image" content="https://www.deskala.in/static/media/deskalaHeaderIcon.6d9e2cd43118c8756b04fa3bdd9650ce.svg" />
      </Helmet>
      {ResearchData.map((item, index) => {
        switch (item.componentType) {
          case "introduction":
            return (
              <CustomIntroduction
                key={index}
                heading1={item.heading1}
                heading2={item.heading2}
                spanTitle2={item.span2}
                img={item.img}
                itemslist={item.itemslist}
              />
            );
          case "userResearch":
            return (
              <WhoWeAre
                key={index}
                heading={item.heading}
                description={item.description}
                heading2={item.heading2}
                cards={item.cards}
              />
            );
          case "uxDesign":
            return (
              <UxDesign
                key={index}
                heading={item.heading}
                // description={item.description}
                // heading2={item.heading2}
                cards={item.cards}
              />
            );
          default:
            <></>;
            break;
        }
      })}
    </div>
  );
};

export default Research;
