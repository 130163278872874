import React from "react";
import classes from "./customModal.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Modal from "react-bootstrap/Modal";

const CustomModal = ({
  title,
  description,
  messageBottom,
  showModal,
  onHideModal
}) => {
  const defaultValues = {
    name: "",
    email: "",
    mobileNo: "",
    message: ""
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="text-danger">{errors[name].message}</small>
      )
    );
  };

  const sendWhatsAppMessage = (formData) => {
    const { name, mobileNo, email, message } = formData;

    // const phoneNumber = "917987030482";
    const phoneNumber = "918878183433";
    const messageText = `Name: ${name}%0A Mobile No: ${mobileNo}%0A email Id: ${email}%0A Message: ${message}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappUrl, "_blank");
  };

  const submithandler = (data) => {
    sendWhatsAppMessage(data);
    setValue("name", "");
    setValue("email", "");
    setValue("mobileNo", "");
    setValue("message", "");
    onHideModal();
  };

  const cancelHandler = () => {
    setValue("name", "");
    setValue("email", "");
    setValue("mobileNo", "");
    setValue("message", "");
    onHideModal();
  };

  return (
    <>
      <Modal
        // {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop // Prevent closing by clicking outside
        onHide={onHideModal} // Close the modal when onHide is triggered
        show={showModal} // Control the modal visibility using show prop
      >
        <Modal.Body className={classes.container}>
          <h4 className={classes.title}>{title}</h4>
          <p className={classes.description}>{description}</p>
          <div className=" w-100  ">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Please provide name" }}
              render={({ field, fieldState }) => (
                <>
                  <label className={classes.label}>Your Name</label>
                  <InputText
                    id={field.name}
                    // disabled={!edit && mode === "update"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    //   useGrouping={false}
                    locale="en-IN"
                    placeholder=" Name"
                    className={` w-100
                 ${fieldState.invalid ? "border border-danger" : ""}  ${
                      classes.inputStyle
                    }  `}
                  />
                </>
              )}
            />
            {getFormErrorMessage("name")}
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-4 ">
            <div className=" w-50  ">
              <Controller
                name="mobileNo"
                control={control}
                rules={{
                  required: "Please provide number",
                  pattern: {
                    value: /^[0-9]{10}$/g,
                    message: "Enter a valid Mobile No"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label className={classes.label}>Your Mobile No</label>
                    <InputText
                      id={field.name}
                      // disabled={!edit && mode === "update"}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      //   useGrouping={false}
                      locale="en-IN"
                      placeholder="Mobile Number"
                      style={{
                        background: "transparent"
                      }}
                      className={` w-100  
                 ${fieldState.invalid ? "border border-danger" : ""} ${
                        classes.inputStyle
                      }   `}
                    />
                  </>
                )}
              />
              {getFormErrorMessage("mobileNo")}
            </div>
            <div className=" w-50  ">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Please provide email",
                  pattern: {
                    value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                    message: "Enter a valid Email Id"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label className={classes.label}>Your Email</label>
                    <InputText
                      id={field.name}
                      // disabled={!edit && mode === "update"}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      //   useGrouping={false}
                      locale="en-IN"
                      placeholder="Email"
                      style={{
                        background: "transparent"
                      }}
                      className={` w-100  
                 ${fieldState.invalid ? "border border-danger" : ""} ${
                        classes.inputStyle
                      } `}
                    />
                  </>
                )}
              />
              {getFormErrorMessage("email")}
            </div>
          </div>
          <div className=" w-100  ">
            <Controller
              name="message"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label className={classes.labelMessage}>Message</label>
                  <InputTextarea
                    id={field.name}
                    // disabled={!edit && mode === "update"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    // useGrouping={false}
                    locale="en-IN"
                    placeholder="Write A Message"
                    style={{
                      background: "transparent"
                    }}
                    className={` w-100 
                 ${fieldState.invalid ? "border border-danger" : ""} ${
                      classes.inputStyle
                    }   `}
                  />
                </>
              )}
            />
          </div>
          <div className={classes.buttonContainer}>
            <button className={classes.cancelButton} onClick={cancelHandler}>
              Cancel
            </button>
            <button
              className={classes.submitButton}
              onClick={handleSubmit(submithandler)}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
