import devlopmentCardImageSvg from "../../../image/servicesPages/development/cards/developmentCardImage.svg";
import developmentCoverSvg from "../../../image/servicesPages/development/developmentCover.jpg";

const itemslistnav = [
  { label: "Research", path: "research", url: "/services/research" },
  { label: "Design", path: "design", url: "/services/design" },
  {
    label: "Development",
    path: "development",
    url: "/services/development"
  },
  { label: "AR&VR", path: "AR&VR", url: "/services/AR&VR" }
];

const DevelopmentData = [
  {
    componentType: "introduction",
    itemslist: itemslistnav,
    // heading: "Web & Mobile",
    heading1: "To Drive Business  ",
    heading2: "Growth",
    span1: "Web & Mobile Development",
    img: developmentCoverSvg,
    className: [
      "introContainer",
      "leftIntro",
      "innerNav",
      "innerItem",
      "title",
      "heading",
      "rightIntro"
    ]
  },
  {
    componentType: "whoWeAre",
    heading: "Product Development",
    description: `At Deskala, we are passionate about translating business challenges into innovative solutions through our strategic Product Development approach.Our expertise
    lies in Node.js, Angular, WordPress, php, Android and iOS App,React, HTML5, CSS, System Design, System Integration, bespoke solutioningand so on.
    With a diverse portfolio spanning EdTech, telephony, and digitalsolutions, Deskala is committed to driving your business's growth and success through innovative
    product development.`
  },
  {
    componentType: "capabilities",
    heading: "Our Project Approach",
    description: `At Deskala, we believe that every business challenge is an opportunity to create something extraordinary. We approach each project with enthusiasm, creativity,
    and a determination to deliver solutions that make a difference.`,
    cards: [
      {
        title: `Understanding Your Needs`,
        description: `We start by getting to know your business
        goals and challenges. This deep understanding
        forms the foundation of our work`
      },
      {
        title: `Strategic Planning`,
        description: `With a clear understanding of your objectives, we develop a customized strategy. This strategy outlines the project's scope, timeline, and milestones, ensuring a roadmap to success.`
      },
      {
        title: `Development Excellence`,
        description: `Our experienced developers use their expertise to turn your vision into a reality. We maintain transparent communication throughout the process, providing you with regular updates.`
      },
      {
        title: `Testing and Quality Assurance`,
        description: `We conduct comprehensive testing to ensure your product meets the highest quality standards. Any bugs or issues are identified and resolved promptly, ensuring a polished final product.`
      },
      {
        title: `Deployment and Support`,
        description: `Once your product is ready, we handle deployment and provide ongoing support. Our commitment doesn't end with deployment; we're here for the long haul to ensure your continued success.`
      }
    ]
  }
  // {
  //   componentType: "whatWeThink",
  //   heading: "What We Think",
  //   cards: [
  //     {
  //       image: devlopmentCardImageSvg,
  //       stratagy: "Deskala Stratagy",
  //       title: "Conquring the next value fronter in private equity",
  //       description: `There are many variations of passages of Lorem Ipsum
  //               available, but the majority have suffered alteration in some
  //               form, by injected`
  //     },
  //     {
  //       image: devlopmentCardImageSvg,
  //       stratagy: "Deskala Stratagy",
  //       title: "Conquring the next value fronter in private equity",
  //       description: `There are many variations of passages of Lorem Ipsum
  //               available, but the majority have suffered alteration in some
  //               form, by injected`
  //     },
  //     {
  //       image: devlopmentCardImageSvg,
  //       stratagy: "Deskala Stratagy",
  //       title: "Conquring the next value fronter in private equity",
  //       description: `There are many variations of passages of Lorem Ipsum
  //               available, but the majority have suffered alteration in some
  //               form, by injected`
  //     }
  //   ]
  // }
];

export default DevelopmentData;
