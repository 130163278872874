import React from "react";
import classes from "./LeftSubContainer.module.css";
import topArrowSvg from "../../../image/CaseStudy/caseStudyArrow.svg";
import { useNavigate } from "react-router-dom";

const LeftSubContainer = () => {
  const navigate = useNavigate();

  const handleCaseStudy = (idName) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const handleClickScroll = (idName) => {
    const element = document.getElementById(idName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className={classes.leftSubContainer}>
      <button
        className={`${classes.caseStudyButton} rounded-pill`}
        onClick={() => handleCaseStudy("caseStudy")}
      >
        <img
          src={topArrowSvg}
          alt="arrow"
          title="arrow Icon"
          className={`${classes.upArrow} rounded-pill`}
        />
        Case Studies
      </button>

      <div className="d-flex flex-column justify-content-start align-items-start">
        <button
          className={classes.leftNavigation}
          onClick={() => handleClickScroll("coverImage")}
        >
          {" "}
          Customer{" "}
        </button>
        <button
          className={classes.leftNavigation}
          onClick={() => handleClickScroll("requirements")}
        >
          {" "}
          Requirement{" "}
        </button>
        <button
          className={classes.leftNavigation}
          onClick={() => handleClickScroll("solutions")}
        >
          {" "}
          Solution{" "}
        </button>
        <button
          className={classes.leftNavigation}
          onClick={() => handleClickScroll("benifits")}
        >
          {" "}
          Benifits{" "}
        </button>
        <button
          className={classes.leftNavigation}
          onClick={() => handleClickScroll("feedback")}
        >
          {" "}
          Feedback{" "}
        </button>
      </div>
    </div>
  );
};

export default LeftSubContainer;
