import React, { useState, useEffect } from "react";
import headingBottomSvg from "../../image/homePage/headingBottom.svg";
import "./style.css";
import recentWorkData from "../../data/HomePageData/RecentWorkData/recentwork";
import { useNavigate } from "react-router-dom";

const RecentWork = () => {
  const navigate = useNavigate();

  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 991);
  const [showAll, setShowAll] = useState(false);
  const defaultItemCount = 3; // Number of items to show by default
  const itemsToShow = showAll ? recentWorkData.length : defaultItemCount;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleCaseStudy = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  // Use an effect to update the responsive state
  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isResponsive) {
      setShowAll(true); // Always show all items on small screens
    } else {
      setShowAll(false); // Hide additional items when screen size increases
    }
  }, [isResponsive]);

  return (
    <div id="caseStudy" className="p-3 p-md-5 bg-white">
      <div className="text-center">
         <h2 className="font-bold mb-0 title-text text-black ">Recent Works </h2>
        <img src={headingBottomSvg} alt="ourRecentBottom" title="bottom line" />
      </div>
      <div className="scrollable-container  ">
        <div className="row p-3 p-md-5 justify-content-start justify-content-lg-start ">
          {recentWorkData.slice(0, itemsToShow).map((work, index) => (
            <div
              key={index}
              className=" col-xl-4 col-lg-6 col-md-6 col-sm-6 col-10 mb-4"
              onClick={() => handleCaseStudy(work.url)}
            >
              <div className="card custom-card ">
                <div className="card-body custom-card d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <h2 className="text-base2  text-wrap">{work.title}</h2>
                    <img
                      src={work.icon}
                      alt={work.title}
                      title={work.title}
                      className="mb-2 logo"
                    />
                  </div>

                  <div className="py-2">
                    <p className="textContent text-wrap">{work.description}</p>
                  </div>
                  <p className="__caseStudy">{work.caseStudy}</p>
                  <div className="py-2">
                    <img
                      src={work.image}
                      alt={work.title}
                      title={work.title}
                      //
                      className=" caseStudyBanner"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ViewMore-container">
        {!isResponsive && (
          <button onClick={toggleShowAll} className="view-more-button">
            {showAll ? "View Less" : "View More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default RecentWork;
