import React, { useState, useEffect } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { ReactComponent as TeamMeeting } from "../../image/homePage/ourTeam/teamMeeting.svg";
import {
  mobileCarouselData,
  teamCarouselData
} from "../../data/HomePageData/TeamsData/TeamsData";
import classes from "./OurTeam.module.css";

const CarouselComponent = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageLoadCount, setImageLoadCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeMobileIndex, setActiveMobileIndex] = useState(0);

  useEffect(() => {
    const imageCount =
      mobileCarouselData.length * 2 + teamCarouselData.length * 2;
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages++;
      setImageLoadCount(loadedImages); // Updated imageLoadCount here

      if (loadedImages === imageCount) {
        setImagesLoaded(true);
      }
    };

    mobileCarouselData.forEach((row) => {
      row.row1.forEach((item) => {
        const img = new Image();
        img.onload = handleImageLoad;
        img.src = item.image;
      });
      row.row2.forEach((item) => {
        const img = new Image();
        img.onload = handleImageLoad;
        img.src = item.image;
      });
    });

    teamCarouselData.forEach((row) => {
      row.row1.forEach((item) => {
        const img = new Image();
        img.onload = handleImageLoad;
        img.src = item.image;
      });
      row.row2.forEach((item) => {
        const img = new Image();
        img.onload = handleImageLoad;
        img.src = item.image;
      });
    });
  }, []);

  // useEffect(() => {
  //   // Prevent automatic slide change when images are still loading
  //   const timer = setInterval(() => {
  //     if (imagesLoaded) {
  //       // If all images are loaded, enable automatic slide change
  //       setActiveIndex(
  //         (prevIndex) => (prevIndex + 1) % teamCarouselData.length
  //       );
  //       setActiveMobileIndex(
  //         (prevIndex) => (prevIndex + 1) % mobileCarouselData.length
  //       );
  //     }
  //   }, 15000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [imagesLoaded]);

  const handleSelect = (selectedIndex) => {
    // Prevent slide change when images are still loading
    if (!imagesLoaded) {
      return activeIndex;
    }
    setActiveIndex(selectedIndex);
  };
  const handleMobileSelect = (selectedIndex) => {
    // Prevent mobile carousel slide change when images are still loading
    if (!imagesLoaded) {
      return activeMobileIndex;
    }
    setActiveMobileIndex(selectedIndex);
  };

  const mobileCarousel = () => {
    return mobileCarouselData.map((row, rowIndex) => (
      <Carousel.Item key={rowIndex} className="p-2  ">
        <Row className="d-flex flex-row justify-content-center">
          {row.row1.map((item, index) => (
            <Col key={index} md={3} className=" w-50 ">
              <div className={classes.webCarouselCard}>
                {imagesLoaded ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    title={item.name}
                    className={classes.cardImage}
                  />
                ) : (
                  <div className={classes.mobileLoader}></div>
                )}

                <h3 className={classes.nameTitle}>{item.name}</h3>
                <p className={classes.nameDetails}>{item.details}</p>
                <div className="d-flex flex-row justify-content-center gap-3">
                  <a
                    href={item.mailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.mailImg}
                      alt="mailImg"
                      title="mail image"
                      className={classes.socialLogo}
                    />
                  </a>
                  <a
                    href={item.linkdinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.linkdinImg}
                      alt="linkedinImg"
                      title="linkdin image"
                      className={classes.socialLogo}
                    />
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className=" d-flex flex-row justify-content-center">
          {row.row2.map((item, index) => (
            <Col key={index} md={3} className=" w-50 ">
              <div className={classes.webCarouselCard}>
                {imagesLoaded ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    title={item.name}
                    className={classes.cardImage}
                  />
                ) : (
                  <div className={classes.mobileLoader}></div>
                )}
                <h3 className={classes.nameTitle}>{item.name}</h3>
                <p className={classes.nameDetails}>{item.details}</p>
                <div className="d-flex flex-row justify-content-center gap-3">
                  <a
                    href={item.mailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.mailImg}
                      alt="mailImg"
                      title="mail image"
                      className={classes.socialLogo}
                    />
                  </a>
                  <a
                    href={item.linkdinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.linkdinImg}
                      alt="linkedinImg"
                      title="linkdin image"
                      className={classes.socialLogo}
                    />
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Carousel.Item>
    ));
  };

  const renderCarouselItems = () => {
    return teamCarouselData.map((row, rowIndex) => (
      <Carousel.Item key={rowIndex} className="p-2" interval={15000} >
        <Row className="d-flex flex-row justify-content-center">
          {row.row1.map((item, index) => (
            <Col key={index} md={3} className=" w-25 ">
              <div className={classes.webCarouselCard}>
                {imagesLoaded ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    title={item.name}
                    className={classes.cardImage}
                  />
                ) : (
                  <div className={classes.loader}></div>
                )}
                <h3 className={classes.nameTitle}>{item.name}</h3>
                <p className={classes.nameDetails}>{item.details}</p>
                <div className="d-flex flex-row justify-content-center gap-3 mb-2">
                  <a
                    href={item.mailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.mailImg}
                      alt="mailImg"
                      title="mail image"
                      className={classes.socialLogo}
                    />
                  </a>
                  <a
                    href={item.linkdinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.linkdinImg}
                      alt="linkedinImg"
                      title="linkdin image"
                      className={classes.socialLogo}
                    />
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className=" d-flex flex-row justify-content-center">
          {row.row2.map((item, index) => (
            <Col key={index} md={3} className=" w-25 ">
              <div className={classes.webCarouselCard}>
                {imagesLoaded ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    title={item.name}
                    className={classes.cardImage}
                  />
                ) : (
                  <div className={classes.loader}></div>
                )}
                <h3 className={classes.nameTitle}>{item.name}</h3>
                <p className={classes.nameDetails}>{item.details}</p>
                <div className="d-flex flex-row justify-content-center gap-3 mb-2">
                  <a
                    href={item.mailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.mailImg}
                      alt="mailImg"
                      title="mail image"
                      className={classes.socialLogo}
                    />
                  </a>
                  <a
                    href={item.linkdinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.linkdinImg}
                      alt="linkedinImg"
                      title="linkdin image"
                      className={classes.socialLogo}
                    />
                  </a>
                </div>
              </div>
              {/* </div> */}
            </Col>
          ))}
        </Row>
      </Carousel.Item>
    ));
  };

  return (
    <div id="team" className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.teamDesc}>
           <h2 className={classes.ouTeam}>Our Team </h2>
          <p className={classes.ourTeamDescription}>
            The team that makes it all work
          </p>
          {/* <TeamMeeting /> */}
        </div>
        <div className={classes.teamCarousel}>
          <Carousel
            controls={false}
            indicators={false}
            activeIndex={activeIndex}
            onSelect={handleSelect}
            
            
          >
            {renderCarouselItems()}
          </Carousel>{" "}
        </div>

        <div className={classes.teamMobileCarousel}>
          <Carousel
            controls={false}
            indicators={false}
            interval={8000}
            activeIndex={activeMobileIndex}
            onSelect={handleMobileSelect}
          >
            {mobileCarousel()}
          </Carousel>{" "}
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
