import React, { useState } from "react";
import OurServices from "../OurServices/OurServices";
import OurProducts from "../OurProducts";
import OurClients from "../OurClients";
import RecentWork from "../RecentWork";
import Testimonial from "../Testimonials";
import ContactUs from "../ContactUs/";
import Welcome from "../Welcome/";
import OurTeam from "../OurTeam/";

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChatbotClick = () => {
    setIsModalOpen(true);
  };
  return (
    <div> 
      <Welcome />
      <OurServices />
      <OurProducts />
      <Testimonial />
      <RecentWork />
      <OurClients />
      <OurTeam />
      <ContactUs />
    </div>
  );
};

export default HomePage;
