import coverImage from "../../../image/CaseStudy/ExotelDeveloperAPIDocumentation/coverImage.jpg";
import sliderImage1 from "../../../image/CaseStudy/ExotelDeveloperAPIDocumentation/imageSlider/image1.jpg";
import sliderImage2 from "../../../image/CaseStudy/ExotelDeveloperAPIDocumentation/imageSlider/image2.jpg";
import olaLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/olaLogo.jpg";
import flipkartLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/flipkartLogo.jpg";
import hdfcLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/hdfcLogo.jpg";
import zomatoLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/zomatoLogo.jpg";
import gojekLogo from "../../../image/CaseStudy/ExotelDeveloperAPIConsole/logos/gojekLogo.jpg";

const exotelCaseStudyData = [
  {
    componentType: "title",
    // context: " Research for Exotel",
    context: " Exotel Developer API documentation",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    url: coverImage,
    className: "coverImg"
  },

  {
    componentType: "description",
    id: "introductionId",
    context: `Exotel is a secure and reliable business phone system on the cloud. It helps businesses to improve their operational efficiency, build better processes, deliver brilliant customer support and most of all, create happy customers! All of this at scale.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `4,000+ Businesses Use Exotel in India and Southeast Asia `,
    className: "content"
  },
  {
    componentType: "client-logos",
    id: "customers",
    url: [olaLogo, flipkartLogo, hdfcLogo, zomatoLogo, gojekLogo],

    className: [
      "marqueeContainer",
      "marquee",
      "marqueeLogoContainer ",
      "marqueeLogoClient "
    ]
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Requirement",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Exotel has made its api documentation available on developer.exotel.com. This documentation includes:`,
    className: "content"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: ["Voice and SMS Restful API"],
    className: ["requirement-container", "arrow", "requirement-content"]
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `These apis can be used by businesses to build high-quality communication applications on the cloud.`,
    className: "content"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: ["Applets"],
    className: ["requirement-container", "arrow", "requirement-content"]
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Businesses can build their own custom workflow using an easy app builder interface where they can drag and drop the necessary applets.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Whenever a new feature is added or they want to make any changes in the old features, Product Managers need technical assistance in making any changes in the doc. Exotel needed to make a CMS catering to its developers to understand their APIs and for their editorial officers to edit the content in an intuitive way`,
    className: "content"
  },
  {
    componentType: "title",
    id: "solutions",
    context: "Solution",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `We developed a bespoke CMS based framework for Exotel where product managers can easily make changes in the doc and publish those changes without any technical assistance.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `We distilled the content into intuitive components and then we imported those components into CMS. Having such a framework ensures that the same design guidelines and layout are followed across the portal`,
    className: "content"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: (
      <>
        The portal is now available on the link{"   "}
        <a href="https://developer.exotel.com" target="_blank" rel="noreferrer">
          https://developer.exotel.com
        </a>
      </>
    ),
    className: "content"
  },
  {
    componentType: "liList",
    id: "benifitList",
    description: `Our solution resulted in meeting the goals of`,
    listItem: [
      "Tangibly fewer support calls",
      "A seamless experience for viewers",
      "Intuitive interface for editors for generating API centric content",
      "Streamlined the publishing process for the Product Management team"
    ],
    className: ["requirement-container", "arrow", "requirement-content"]
  },

  // {
  //   componentType: "title",
  //   id: "carouselThirdTitle",
  //   context: "COMPONENT PHOTO (ADMIN VIEW AND DEV VIEW)",
  //   className: "sub-heading"
  // },
  {
    componentType: "imageSlider",
    id: "carouselThird",
    url: [sliderImage1, sliderImage2],
    className: ["carousel-wrapper", "carousel-image-wrapper", "carousel-image"]
  },
  {
    componentType: "title",
    id: "benifits",
    context: "Benefits",
    className: "sub-heading"
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      "Ease of Use and intuitive components for editing",
      "User Access Control",
      "Seamless outreach for Production Management and Marketing teams",
      "Fewer support calls",
      "Reusable components for publishing to CMS"
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "title",
    id: "feedback",
    context: "Feedback",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: `“On behalf of Exotel, I would like to acknowledge the great work done by Deskala in providing us an easy-to-use CMS for our Developer Portal. The quality of work and timely deliverables have made it possible for us to roll it out before the year ends. Really appreciate the diligent approach taken in the product design and issue resolution. Special mention for Prakash on his prompt responsiveness and showing maturity in owning the project.” Avnish Gupta, Associate Director of Products, Exotel`,
    className: "content"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: `Avnish Gupta, Associate Director of Products, Exotel`,
    className: "content"
  }
];

export default exotelCaseStudyData;
