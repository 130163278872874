import React, { useState } from "react";
import classes from "./welcome.module.css";
import CustomModal from "../../components/CustomModal/CustomModal";
import threeLinesLeftSvg from "../../image/threelinesStarLeft.svg";
import homePageAnimation from "../../image/home-page-animation.svg";
import homeRectangle2 from "../../image/homeRectangle.jpg";
import starrSvg from "../../image/stars.svg";
import threeLinesrightSvg from "../../image/threeLinesStarRight.svg";


const Welcome = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  return (
    <div className={classes.mainContainer} id="home">
      <div className={classes.container}>
        <div className={classes.BuildContainer}>
          <img src={threeLinesLeftSvg} alt="threeLines" title="three Lines " />
          <span className={classes.buildTitle}>We Build Various</span>
          <img src={threeLinesrightSvg} alt="threeLines" />
        </div>
        <div className={classes.animationContainer}>
          <img
            src={homeRectangle2}
            alt="home-rectangle"
            title="home-rectangle"
            className={classes.rectangleImage}
          />
          <div className={classes.productTitleContainer}>
             <h1 className={classes.productTitle}>
              Products, Brands & Experiences
             </h1>
          </div>
          <div className={classes.animationLeft}>
            <img
              src={homePageAnimation}
              alt="homePageAnimation"
             title="home Page Animation"
              className={classes.animationLeftImage}
            />
          </div>
          <div className={classes.animationRight}>
            <img
              src={homePageAnimation}
              alt="homePageAnimation"
              title="home Page Animation"
              className={classes.animationRightImage}
            />
          </div>
        </div>

        <div className={classes.bottomDescriptionContainer}>
          <img src={starrSvg} alt="stars" title="Stars" />
          <h2 className={classes.bottomDescription}>
            The art (kala) of designing solutions to <br />
            Serve | Create | Partner.
          </h2>
          <img src={starrSvg} alt="stars" title="Stars" />
        </div>
      </div>
      <div className="text-center">
        <button className={classes.button} onClick={handleShowModal}>
          UI/UX Classes
        </button>
      </div>
      {showModal && (
        <CustomModal
          title="UI/UX Classes & Workshop"
          description="Share your details to connect with UX Mentors"
          messageBottom="Let us know your Level of expertise"
          showModal={showModal}
          onHideModal={handleHideModal}
        />
      )}
    </div>
  );
};

export default Welcome;
