import coverImage from "../../../image/CaseStudy/vehito/coverImage/coverImage.jpg";
import researchImage1 from "../../../image/CaseStudy/vehito/research/image1.jpg";
import researchImage2 from "../../../image/CaseStudy/vehito/research/image2.jpg";
import customerJourneyImage1 from "../../../image/CaseStudy/vehito/customerJourney/image1.jpg";
import customerJourneyImage2 from "../../../image/CaseStudy/vehito/customerJourney/image2.jpg";
import carouselImage1 from "../../../image/CaseStudy/vehito/carousel/image1.jpg";
import carouselImage2 from "../../../image/CaseStudy/vehito/carousel/image2.jpg";
import carouselImage3 from "../../../image/CaseStudy/vehito/carousel/image3.jpg";

const vehitoCaseStudyData = [
  {
    componentType: "title",
    context: " Car Garage Business Management",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    url: coverImage,
    className: "coverImg"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Vehito is a unique auto care solution provider in the Indian market, that is trying to satisfy unmet needs of customers that are often ignored by various solution providers.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Vehito, provides a strong platform to help vehicle manufacturers, auto component distributors and garages to ensure that the end customers’ vehicle is always a Perfect Go. Vehito bridges multiple channels among all their partners to ensure that all vehicle owners are at peace about the upkeep and maintenance of their vehicle.`,
    className: "content"
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Requirement",
    className: "sub-heading"
  },
  // {
  //   componentType: "description",
  //   id: "introductionId",
  //   context: `Vehito was looking for understanding user behaviour of car garage owner and discover the various important factors to help them successfully bring them online`,
  //   className: "content"
  // },
  {
    componentType: "liList",
    id: "requirementList",
    description: `Vehito was looking for understanding user behaviour of car garage owner and discover the various important factors to help them successfully bring them online`,
    listItem: [
      "Garage Job Management ",
      "Customer Tracking",
      "Identifying Service Issues",
      "Ordering Spare parts",
      "Manage Service history"
    ],
    className: ["requirement-container", "arrow", "requirement-content"]
  },
  {
    componentType: "title",
    id: "solutions",
    context: "Solution",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `Deskala helped Vehito to build a mobile app based B2B solution that allows the unorganised auto service market, to provide the car consumer a high quality vehicle service experience. It also enables various garage owners to order genuine car spare parts online, and provide a seamless experience for next service scheduling.`,
    className: "content"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `Vehito founding team along with the deskala team conducted actual ground research to ensure that we identify the challenges the users face in the field.    `,
    className: "content"
  },
  {
    componentType: "description",
    id: "solutionDescription",
    context: `Deskala aims to be a continuous partner in ensuring that Vehito as a product can improve the livelihood on people involved in this trade.`,
    className: "content"
  },

  {
    componentType: "title",
    id: "STAKEHOLDER/USER RESEARCH",
    context: "STAKEHOLDER/USER RESEARCH",
    className: "sub-heading"
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [researchImage1, researchImage2],
    className: ["carousel-wrapper", "carousel-image-wrapper", "carousel-image"]
  },
  {
    componentType: "title",
    id: "CUSTOMER JOURNEY/INFORMATION ARCHITECTURE",
    context: "CUSTOMER JOURNEY/INFORMATION ARCHITECTURE",
    className: "sub-heading"
  },
  {
    componentType: "imageSlider",
    id: "carouselSecond",
    url: [customerJourneyImage1, customerJourneyImage2],
    className: ["carousel-wrapper", "carousel-image-wrapper", "carousel-image"]
  },
  {
    componentType: "title",
    id: "carouselThirdTitle",
    context: "CUSTOMER JOURNEY/INFORMATION ARCHITECTURE",
    className: "sub-heading"
  },
  {
    componentType: "carousel",
    id: "carouselThird",
    url: [carouselImage1, carouselImage2, carouselImage3],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  },
  {
    componentType: "title",
    id: "benifits",
    context: "Benefits",
    className: "sub-heading"
  },
  {
    componentType: "liList",
    id: "benifitList",
    listItem: [
      "Easy garage onboarding",
      "Customer car service information history",
      "Genuine parts delivery from OEM directly from the app",
      "Designed specifically for garage workforce",
      "End customer car inspection/assessment"
    ],
    className: ["requirement-container", "circularIcon", "requirement-content"]
  },
  {
    componentType: "title",
    id: "feedback",
    context: "Feedback",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: `‘Being in B2B space UX designing was indeed a tougher and challenging job for us. Perfect user interface and ease of usability are the main mantras for a successful mobile application. Deskala helped us in providing top class design and workflow that enabled our customers to upgrade themselves on a digital platform without any hassles. Thanks to the entire Deskala team to making Vehito as one of the best designed application in auto sector.’`,
    className: "content"
  },
  {
    componentType: "description",
    id: "feedbackDescription",
    context: `Sreenivas, CEO, Sunwiztech`,
    className: "content"
  }
];

export default vehitoCaseStudyData;
