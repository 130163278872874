import React from "react";
import classes from "./vr.module.css";
import VRand3DData from "../../../data/ServicesData/Vr&3dData/Vr&3dData";
import CustomIntroduction from "../../../components/ServicesComponent/Introduction/CustomIntroduction";
import WhoWeAre from "../../../components/ServicesComponent/VRWhoWeAre/WhoWeAre";
import WhatWeDo from "../../../components/ServicesComponent/VRWhatWeDo/WhatWeDo";
import WhatWeThink from "../../../components/ServicesComponent/VRWhatWeThink/WhatWeThink";
import WhatWeThinkScroll from "../../../components/ServicesComponent/VRWhatWeThinkScroll/WhatWeThinkScroll";
import WorkshopCards from "../../../components/ServicesComponent/VRWorkshopCards/WorkshopCards";
import { Helmet } from 'react-helmet-async';

const VR = () => {
  return (
    <div className={` ${classes.mainContainer}`}>
      <Helmet>
        <title>Deskala- AR&VR </title>
        <link rel="canonical" href="https://www.deskala.in/services/AR&VR" />
        <meta name="description" content="This is a Deskala website AR and VR page" />
      </Helmet>
      {VRand3DData.map((item, index) => {
        switch (item.componentType) {
          case "introduction":
            return (
              <CustomIntroduction
                key={index}
                heading1={item.heading1}
                heading2={item.heading2}
                spanTitle2={item.span2}
                img={item.img}
                itemslist={item.itemslist}
              />
            );
          case "whoWeAre":
            return (
              <WhoWeAre
                key={index}
                heading={item.heading}
                description={item.description}
              />
            );

          case "whatWeDo":
            return (
              <WhatWeDo key={index} heading={item.heading} cards={item.cards} />
            );
          case "workshopCards":
            return (
              <WorkshopCards
                key={index}
                heading={item.heading}
                description={item.description}
                cards={item.cards}
              />
            );

          default:
            <></>;
            break;
        }
      })}
    </div>
  );
};

export default VR;
