import React from "react";
import classes from "./capabilities.module.css";
import Heading from "../Heading/Heading";
import Description from "../Description/Description";
import CustomCardTextNdDesc from "../CustomCardText&Desc/CustomCardText&Desc";

const Capabilities = ({ heading, description, cards }) => {
  return (
    <div className={classes.capabilitiesContainer}>
      <div className={classes.capabilitiesSubContainer}>
        <Heading heading={heading} />
        <div className="">
          <Description description={description} />
        </div>
      </div>

      <div className={classes.customCardContainer}>
        <div className={`${classes.cardsContainer} `}>
          {cards.map((card, index) => {
            return (
              <CustomCardTextNdDesc
                key={index}
                title={card.title}
                description={card.description}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "#3D5860" }
                    : { backgroundColor: "#0C1117" }
                }
              />
            );
          })}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Capabilities;
