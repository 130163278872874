import React from "react";
import "./style.css";

const Title = ({ context, className = "", style = {} }) => {
  return (
    <div className={` ${className}`} style={style}>
      {context}
    </div>
  );
};

export default Title;
