import React from "react";
// import "./style.css";
import classes from "./footer.module.css";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import deskalaLogoWhiteSvg from "../../image/homePage/deskalaFooterIcon.svg";
import fbLogoSvg from "../../image/socialLogos/facebookIcon.svg";
import twitterLogoSvg from "../../image/socialLogos/twitterIcon.svg";
import linkdinLogoSvg from "../../image/socialLogos/linkedinIcon.svg";
import instagramLogoSvg from "../../image/socialLogos/instagramIcon.svg";

const Footer = () => {
  const navigate = useNavigate();
  const handleClickScroll = (idName) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${classes.footer}`);
        const footerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - (footerHeight - 140);

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };
  return (
    <>
      <div className={`${classes.footer}`}>
        <div className="w-75">
          <div className="d-flex flex-column gap-3 ">
            <img
              src={deskalaLogoWhiteSvg}
              alt="deskala-logo"
              title="deskala-logo"
              className={classes.deskalaLogo}
            />
            {/* <p className={`w-xl-25 w-lg-50 wrap ${classes.deskalaAddress}`}>
              University office rayasanadram Bangalore MA 09854 1-987-37
            </p> */}
          </div>

          <div className="d-flex flex-wrap flex-md-row justify-content-lg-start justify-content-start  flex-wrap  col-lg-12 ">
            <div className="d-flex flex-column flex-lg-column justify-content-center justify-content-xl-start justify-content-sm-center align-items-start col-6 col-xl-3   ">
            <h4
                className={classes.linkHeader}
                onClick={() => handleClickScroll("home")}
              >
                Home
              </h4>
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("home")}
              >
                About
              </h4>
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("clients")}
              >
                Clients
              </h4>
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("enquiry")}
              >
                Contact Us
              </h4>
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("team")}
              >
                Our Team
              </h4>
            </div>
            <div className="d-flex flex-column flex-lg-column  justify-content-lg-start justify-content-sm-center align-items-start col-6 col-xl-3  ">
              {/* <h4 className={classes.linkTitle}>Customers</h4> */}
              {/* <h4 className={classes.linkTitle}>Partners</h4> */}
              {/* <h4 className={classes.linkTitle}>Blog</h4> */}
              <h1
                className={classes.linkHeader}
                onClick={() => handleClickScroll("products")}
              >
                Products
              </h1>
              <h4 className={classes.linkText}>
                <a href="/products/skalebot" className={classes.linkText} tabIndex={0}>
                  SkaleBot
                </a>
              </h4>

              <h4 className={classes.linkText}>
                <a className={classes.linkText} href="/products/skalework" tabIndex={0}>
                  SkaleWork
                </a>
              </h4>
              <h4 className={classes.linkText}>
                <a className={classes.linkText} tabIndex={0}>
                Skale Inventory
                </a>
              </h4>
              <h4 className={classes.linkText}>
                <a className={classes.linkText}  tabIndex={0}>
                Skale Bill
                </a>
              </h4>
            </div>
            <div className="d-flex flex-column flex-lg-column  justify-content-lg-start justify-content-sm-center align-items-start col-6 col-xl-3   ">
              <h4
                className={classes.linkHeader}
                onClick={() => handleClickScroll("services")}
              >
                Services
              </h4>
              <h4
                className={classes.linkTitle}
              >
                <a className={classes.linkText} href="/services/research" tabIndex={0}>
                  Research
                </a>
              </h4>
              <h4
                className={classes.linkTitle}
              >
                <a className={classes.linkText} href="/services/design" tabIndex={0}>
                  Design
                </a>
              </h4>

              <h4
                className={classes.linkTitle}
              >
                <a className={classes.linkText} href="/services/development" tabIndex={0}>
                Development
                </a>
              </h4>
              <h4
                className={classes.linkTitle}
              >
                <a className={classes.linkText} href="/services/AR&VR" tabIndex={0}>
                AR&VR
                </a>
              </h4>
              
            </div>
            <div
              className={`d-flex flex-wrap flex-row  justify-content-xl-end  gap-3 col-7 col-xl-3  `}
            >
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2FDeskalaDesign"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={twitterLogoSvg}
                  alt="twitterLogo"
                  title="twitter-logo"
                  className={classes.socialShareLogo}
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100057829712479"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={fbLogoSvg}
                  alt="fbLogo"
                  title="facebook-logo"
                  className={classes.socialShareLogo}
                />
              </a>
              <a
                href="https://in.linkedin.com/company/deskala"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkdinLogoSvg}
                  alt="linkdinLogo"
                  title="Linkdin-logo"
                  className={classes.socialShareLogo}
                />
              </a>
              {/* <a
                href="https://www.facebook.com/profile.php?id=100057829712479"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagramLogoSvg}
                  alt="instagramLogo"
                  className={classes.socialShareLogo}
                />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.copyrightsContainer}>
        <p className={classes.copyrightsText}>
          {/* @ 2023 &copy; Copyrights. All rights reserved */}
          Copyrights &copy; 2024 Deskala Research and Design Consulting Pvt. Ltd
        </p>
      </div>
    </>
  );
};

export default Footer;
