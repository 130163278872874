import React from "react";
import classes from "./customCardText&Desc.module.css";

const CustomCardTextNdDesc = ({ title, description, style = {} }) => {
  return (
    <div className={classes.customCardContainer}>
      <div className={`${classes.cardsContainer} `}>
        <div className={`${classes.customCard} `} style={style}>
          <h3 className={classes.cardTitle}>{title}</h3>
          <p className={classes.cardDescription}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomCardTextNdDesc;
