import skalebot from "../../../image/homePage/recentWork/skalebotDetails.png";
import udemy from "../../../image/homePage/recentWork/udemy.png";
import exotelDocumentation from "../../../image/homePage/recentWork/exotelDocumentation.png";
import vehito from "../../../image/homePage/recentWork/vehito.png";
import istills from "../../../image/homePage/recentWork/istills.png";
import exotelConsole from "../../../image/homePage/recentWork/exotelConsole.png";
import leadsquaredResearch from "../../../image/homePage/recentWork/leadsquaredResearch.png";
import leadsquaredTool from "../../../image/homePage/recentWork/leadsquaredTool.png";
import riyaDesign from "../../../image/homePage/recentWork/riyaDesign.jpg";
import arrowIcon from "../../../image/homePage/recentWork/arrowIcon.svg";
const recentWorkData = [
  {
    title: "Skalebot - User-Friendly Chatbot for Businesses",
    description:
      "Skalebot is a user-friendly chat bot designed to empower businesses. This trusted sales assistant is seamlessly integrated with WhatsApp, revolutionizing customer service. Skalebot excels in personalized broadcast messages, enhancing marketing and promotional strategies. Elevate your business with Skalebot today.",
    image: skalebot,
    icon: arrowIcon,
    caseStudy: "Design, Development - Case Study",
    url: "/caseStudy/skalebot"
  },
  {
    title: "Udemy India Research",
    description:
      "Learn more about our research journey with Udemy India to discover how we navigated the complexities of the ed-tech market and provided actionable insights for effective decision-making. ",
    image: udemy,
    icon: arrowIcon,
    caseStudy: "User Research - Case Study",
    url: "/caseStudy/udemy"
  },
  {
    title: "Exotel Developer API Documentation ",
    description: `Discover how we helped Exotel to improve their operational efficiency and build better processes with our bespoke CMS solution.    `,
    image: exotelDocumentation,
    icon: arrowIcon,
    caseStudy: "Development - Case Study",
    url: "/caseStudy/exotel"
  },
  {
    title: "Vehito - Car Garage Business Management",
    description:
      "Find out how we collaborated with Vehito to develop a mobile app-based B2B solution that offers high-quality vehicle service experiences to the unorganized auto service market.",
    image: vehito,
    icon: arrowIcon,
    caseStudy: "Design, Development - Case Study",
    url: "/caseStudy/vehito"
  },
  {
    title: "Rental E-commerce iStills.in",
    description:
      "Explore how we helped iStills and dive into the journey of how we've reshaped the landscape of photography equipment rentals.",
    image: istills,
    icon: arrowIcon,
    caseStudy: "Design, Development - Case Study",
    url: "/caseStudy/istills"
  },
  {
    title: "Exotel Developer API Console",
    description:
      "Get the Inside Track on How We Improved Exotel's Developer Experience and made a difference.",
    image: exotelConsole,
    icon: arrowIcon,
    caseStudy: "Development - Case Study",
    url: "/caseStudy/exotelApiConsole"
  },
  {
    title: "Leadsquared Marketing Cloud Research ",
    description:
      "Get a Peek into How We Pioneered Marketing Insights for LeadSquared, a Top CRM Player in India. ",
    image: leadsquaredResearch,
    icon: arrowIcon,
    caseStudy: "Design, Development - Case Study",
    url: "/caseStudy/leadSquaredMarketing"
  },
  {
    title: "Leadsquared Customer Success Tool ",
    description:
      "Learn how Deskala Enhanced Leadsquared Customer Success. Discover more here.",
    image: leadsquaredTool,
    icon: arrowIcon,
    caseStudy: "Design, Development - Case Study",
    url: "/caseStudy/leadSquaredCustomer"
  }
  // Add more objects as needed
];

export default recentWorkData;
