import personalizedCatalogSvg from "../../../image/productpages/skalebot/cardImg1.svg";
import marketingImageSVG from "../../../image/productpages/skalebot/marketingImage.svg";

export const introData = {
  title: "Skalebot",
  subTitle: "The Smart Way of doing Business",
  content: [
    "Scale your <b>B</b>uisness sales. <b>O</b?pportunities. <b>T</b>argets",
    "Trusted Sales Assistant to boost your Business"
  ],
  buttons: [
    { label: "VISIT WEBSITE", bgColor: "bg-white" },
    { label: "SCHEDULE DEMO", bgColor: "bg-black text-white" }
  ]
};

export const videoData = {
  title: "Video Tour In Skalebot",
  subTitle: "Discover Our Product more with Video",
  videoSrc: "https://www.youtube.com/embed/c_Q_RwU57iM?si=uvVG2KeNbZJNfAeZ"
};

export const benifitsData = [
  {
    image: personalizedCatalogSvg,
    title: "Personalized Catalogs",
    description:
      "Craft and share personalized product catalogs effortlessly enhancing your sales strategy."
  },
  {
    image: personalizedCatalogSvg,
    title: "Personalized Catalogs",
    description:
      "Craft and share personalized product catalogs effortlessly enhancing your sales strategy."
  },
  {
    image: personalizedCatalogSvg,
    title: "Personalized Catalogs",
    description:
      "Craft and share personalized product catalogs effortlessly enhancing your sales strategy."
  },
  {
    image: personalizedCatalogSvg,
    title: "Personalized Catalogs",
    description:
      "Craft and share personalized product catalogs effortlessly enhancing your sales strategy."
  },
  {
    image: personalizedCatalogSvg,
    title: "Personalized Catalogs",
    description:
      "Craft and share personalized product catalogs effortlessly enhancing your sales strategy."
  },
  {
    image: personalizedCatalogSvg,
    title: "Personalized Catalogs",
    description:
      "Craft and share personalized product catalogs effortlessly enhancing your sales strategy."
  }
];

export const MarketingData = [
  {
    image: marketingImageSVG,
    publishBy: "Alec Whitten • 17 Jan 2022",
    publishTitle: "Bill Walsh leadership lessons",
    publishDescription:
      "Like to know the secrets of transforming a 2-14 team into a 3xSuper Bowl winning Dynasty?",
    badges: ["LeaderShip", "Management"]
  },
  {
    image: marketingImageSVG,
    publishBy: "Alec Whitten • 17 Jan 2022",
    publishTitle: "Bill Walsh leadership lessons",
    publishDescription:
      "Like to know the secrets of transforming a 2-14 team into a 3xSuper Bowl winning Dynasty?",
    badges: ["LeaderShip", "Management"]
  },
  {
    image: marketingImageSVG,
    publishBy: "Alec Whitten • 17 Jan 2022",
    publishTitle: "Bill Walsh leadership lessons",
    publishDescription:
      "Like to know the secrets of transforming a 2-14 team into a 3xSuper Bowl winning Dynasty?",
    badges: ["LeaderShip", "Management"]
  }
];
