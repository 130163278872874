import "./index.css";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Arrow } from "../../svg/rightArrow.svg";
import { useEffect, useState } from "react";
// import { useTheme } from '../../Context/ThemeContext'
// import classes from "./innerNav.module.css";

export default function InnerNav({
  className = "",
  itemslist,
  RElement = () => {}
}) {
  const navigate = useNavigate();
  const [head, sethead] = useState(`/`);
  useEffect(() => {
    let href = document.location.href
      .slice(document.location.origin.length)
      .split("/")
      .slice(1);
    if (href.length > 1) {
      href = href.slice((x) => x !== "");
    }

    sethead(href);
  }, [document.location.href]);
  //   const {theme}=useTheme()

  return (
    <div className=" d-flex flex-row align-items-center justify-content-center">
      {itemslist.map((item, idx) => {
        return (
          <div
            key={idx}
            className="d-flex align-items-center "
            onClick={() => navigate(item.url)}
          >
            <div
              className={`w-4rem sm:w-8rem md:w-8rem h-2.5rem sklbt-in-nav d-flex justify-content-center align-items-center mx-2 cursorPointer ${
                item?.className
              } ${head.includes(item.path) ? `selected-nav-el` : ``}`}
            >
              {item.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}
