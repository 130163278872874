import React from "react";
import headingBottomSvg from "../../image/homePage/headingBottom.svg";
import moreSvg from "../../image/homePage/rightAngleBracket.svg";
import services from "../../data/HomePageData/ServicesData/services";
import "./style.css";
import { useNavigate } from "react-router-dom";
const OurServices = () => {
  const navigate = useNavigate();

  const handleServicePage = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };
  return (
    <div id="services" className="p-3 p-md-5  ourServices-background">
      <div className="text-center">
         <h2 className="font-bold mb-0 title-text text-white">Our Services </h2>
        <img src={headingBottomSvg} alt="ourServicesBottom" title="bottom line"/>
      </div>
      <div className="scrollable-container ">
        <div className="row p-3 p-md-5 justify-content-start justify-content-xl-center justify-content-start ">
          {services.map((service, index) => (
            <div
              key={index}
              className=" col-xl-3 col-lg-4  col-sm-6  col-10 mb-4 "
              onClick={() => handleServicePage(service.url)}
            >
              <div className="card px-4 py-3 ">
                <div className="card-body d-flex flex-column ">
                  <div className="text-center mt-5 mb-3">
                    <img
                      src={service.icon}
                      alt={service.title}
                      title={service.title}
                      className="mb-4 logo"
                    />
                     <h2 className="font-bold text-base mb-2">
                      {service.title}
                     </h2>
                  </div>
                  <div className="flex-grow-1">
                    <p className="textContent text-center text-wrap">
                      {service.description}
                    </p>
                  </div>
                  <button className=" border-0 bg-white d-flex justify-content-center align-items-center m-3 cursor-pointer">
                    <span className="font-normal cursor-pointer text-black">
                      Learn More
                    </span>
                    <img src={moreSvg} alt="moreSvg" title="more svg" className="mx-1 moreSvg" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
