import marketingImageSVG from "../../../image/productpages/skalebot/marketingImage.svg";
import headphoneSvg from "../../../image/ProductsImages/skalework/cardsImage/headphones.svg";
import charbarSvg from "../../../image/ProductsImages/skalework/cardsImage/chartbar.svg";
import card1Svg from "../../../image/productpages/skaleWork/cardsImages/card1.svg";
import card2Svg from "../../../image/productpages/skaleWork/cardsImages/card2.svg";
import card3Svg from "../../../image/productpages/skaleWork/cardsImages/card3.svg";
import card4Svg from "../../../image/productpages/skaleWork/cardsImages/card4.svg";
import card5Svg from "../../../image/productpages/skaleWork/cardsImages/card5.svg";
import card6Svg from "../../../image/productpages/skaleWork/cardsImages/card6.svg";
import marketingImage1 from "../../../image/productpages/skaleWork/marketingImage1.jpg";
import marketingImage2 from "../../../image/productpages/skaleWork/marketingImage2.jpg";
import marketingImage3 from "../../../image/productpages/skaleWork/marketingImage3.jpg";

export const introData = {
  title: "Skalebot",
  subTitle: "The Smart Way of doing Business",
  content: [
    "Scale your <b>B</b>uisness sales. <b>O</b?pportunities. <b>T</b>argets",
    "Trusted Sales Assistant to boost your Business"
  ],
  buttons: [
    { label: "VISIT WEBSITE", bgColor: "bg-white" },
    { label: "SCHEDULE DEMO", bgColor: "bg-black text-white" }
  ]
};

export const videoData = {
  title: "Take a video tour of Skalework",
  // subTitle: "Take a video tour of Skalework to explore our product further.",
  subTitle: " to explore our product further.",
  videoSrc: "https://www.youtube.com/embed/iX3p9yhY9BA?si=dkaa8sSn3NukQk0Z"
};

export const benifitsData = [
  {
    image: card1Svg,
    title: "Save time and effort",
    description:
      "Skalework will help you organize the information you need to run your business"
  },
  {
    image: card2Svg,
    title: "Seamless Digitization:",
    description:
      "SkaleWork's digitization capabilities simplify data entry and management, with an intuitive and user-friendly dashboard"
  },
  {
    image: card3Svg,
    title: "Data-Driven Decision-Making",
    description:
      "SkaleWork empowers manufacturers with real-time access to data on product baselines."
  },
  {
    image: card4Svg,
    title: "Efficiency Optimization",
    description:
      "SkaleWork efficiently manages work orders, ensuring a smooth workflow throughout the manufacturing process."
  },
  {
    image: card5Svg,
    title: "Connected Workforce",
    description:
      "SkaleWork allows you to monitor both employees and machines, fostering connectivity and efficiency throughout your manufacturing operations."
  },
  {
    image: card6Svg,
    title: "Optimized Centralization",
    description:
      "SkaleWork provides a centralized platform that optimizes your manufacturing processes, right from the initial stages to the final product."
  }
];

export const MarketingData = [
  {
    image: marketingImage1,
    publishBy: "Linkedin • 2 days ago",
    publishTitle: "Benefits of Skalework ",
    publishDescription: "Why your business should have Skalework?",
    badges: ["Skalework", "Management", "Ulitimate Business"],
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7033665600212152320"
  },
  {
    image: marketingImage2,
    publishBy: "Youtube • 2 months ago",
    publishTitle: "Streamline Your Operations ",
    publishDescription: "Simplify your business with Skalework",
    badges: ["Skalework", "Save Time & Effort", "Digitization"],
    url: "https://youtu.be/nXDq-ty6mOs?si=zYFeojy2CjrhVX_G"
  },
  {
    image: marketingImage3,
    publishBy: "Linkedin • 2 days ago",
    publishTitle: "Unlocking the Potential",
    publishDescription: "What Skalework can do for your Business",
    badges: ["Skalework", "Management", "Business Monitor"],
    url: "https://www.linkedin.com/feed/update/urn:li:activity:6997791913969926144"
  }
];
