import React from "react";
import classes from "./customCard.module.css";
import CardTitle from "../CardTitle/CardTitle";
import CardDescription from "../CardDescription/CardDescription";
import CardStratagy from "../CardStratagy/CardStratagy";

const CustomCard = ({
  stratagyTitle,
  cardTitle,
  cardDescription,
  cardImage,
  style = {}
}) => {
  return (
    <div className={`${classes.customCard}`}>
      <div className={classes.cardImageContainer}>
        <img src={cardImage} alt="card"  title="card Image" className={classes.cardImage} />
      </div>
      <CardStratagy stratagyTitle={stratagyTitle} />
      <CardTitle cardTitle={cardTitle} />

      <CardDescription cardDescription={cardDescription} />
    </div>
  );
};

export default CustomCard;
