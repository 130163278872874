import coverImage from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/coverImage/coverImage.jpg";
import researchImage1 from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/research/image1.jpg";
import researchImage2 from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/research/image2.jpg";
import wireframeImage1 from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/wireframe/image1.jpg";
import wireframeImage2 from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/wireframe/image2.jpg";
import designGoalImage1 from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/designGoal/image1.jpg";
import designGoalImage2 from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/designGoal/image2.jpg";
import visualImage from "../../../image/CaseStudy/LeadsquaredCustomerSuccessTool/visualDesign/image1.jpg";

const LeadSquaredCustomerData = [
  {
    componentType: "title",
    context: "Leadsquared Customer Success Tool",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    url: coverImage,
    className: "image"
  },
  {
    componentType: "title",
    context: "Business Problem",
    className: "sub-heading"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `Leadsqaured is one of the leading CRM product in India. The management understood the requirement for having Customer Success managers.`,
      `The major challenge was due to the scattered department wise information processing and visibility.`,
      `Deskala conducted a research with Internal Sales and Other account management teams across the company. This helped us identify the open areas for information availability.`
    ]
  },
  {
    componentType: "title",
    id: "requirements",
    context: "User Research/ User Persona",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `We were expected to conduct a competitive study of available tools, to understand the current market offering. Once we understood the depth possible in Leadsquared.
    Deskala conducted actual user research to understand the GAPs in current tool.`,
    className: "content"
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [researchImage1, researchImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  },
  {
    componentType: "title",
    id: "benifits",
    context: "Wireframes",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Deskala was able to establish the user journey that would help users take more effective decision about their accounts related action.`,
    className: "content"
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [wireframeImage1, wireframeImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  },

  {
    componentType: "title",
    id: "solutions",
    context: " Design Goal",
    className: "sub-heading"
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `Since Customer Success team members were expected to handle multiple accounts.`,
      `The key goal was to identify important data points that will help them decide on the action that needs to taken with respect to the account that they are currently manage.`,
      `Billing, Product Usage & Product Adoption were some important metrics.`
    ]
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [designGoalImage1, designGoalImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  },
  {
    componentType: "title",
    id: "feedback",
    context: "Visual Design",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Since Leadsquared was looking to adopt a more dark tone of the product. Deskala created a new
    language that can help there product adopt a dark theme across products.`,
    className: "content"
  },
  {
    componentType: "CoverImage",
    id: "coverImage",
    url: visualImage,
    className: "image"
  }
];

export default LeadSquaredCustomerData;
