import researchcover from "../../../image/servicesPages/Research/coverImage.jpg";
import cardImage1 from "../../../image/servicesPages/Research/cardImages/image1.jpg";
import cardImage2 from "../../../image/servicesPages/Research/cardImages/image2.jpg";
import cardImage3 from "../../../image/servicesPages/Research/cardImages/image3.jpg";
import cardImage4 from "../../../image/servicesPages/Research/cardImages/image4.jpg";
import cardImage5 from "../../../image/servicesPages/Research/cardImages/image5.jpg";

const itemslistnav = [
  { label: "Research", path: "research", url: "/services/research" },
  { label: "Design", path: "design", url: "/services/design" },
  {
    label: "Development",
    path: "development",
    url: "/services/development"
  },
  { label: "AR&VR", path: "AR&VR", url: "/services/AR&VR" }
];

const ResearchData = [
  {
    componentType: "introduction",
    itemslist: itemslistnav,
    heading1: "Pioneering ",
    heading2: "Innovation Through ",
    span2: "User Research Excellence",

    img: researchcover,
    className: [
      "introContainer",
      "leftIntro",
      "innerNav",
      "innerItem",
      "title",
      "heading",
      "rightIntro"
    ]
  },
  {
    componentType: "userResearch",
    heading: `User Research & User Testing`,
    description: `In the ever-evolving landscape of business, innovation reigns supreme. When it comes to innovation, nothing is as invaluable as meticulous as User Research.
    At Deskala, we excel in innovation-driven User Research that serves as the cornerstone for creating, developing, and truly understanding your users and their
    needs. We specialize in User Research methods like Ethnographic Research, Contextual Inquiries, User Interviews, Participatory Design and User Validation
    activities to generate successful design solutions.`

    // heading2: "Product Design Consulting",
    // cards: [
    //   { image: cardImage1, title: `Product domain study` },
    //   { image: cardImage2, title: `Product research` },
    //   { image: cardImage3, title: `Product Strategy` },
    //   { image: cardImage4, title: `Product Positioning & Packaging` },
    //   { image: cardImage5, title: `Product Planning & Execution` }
    // ]
  },
  {
    componentType: "uxDesign",
    heading: "Product Design Consulting",
    cards: [
      { image: cardImage1, title: `Product domain study` },
      { image: cardImage2, title: `Product research` },
      { image: cardImage3, title: `Product Strategy` },
      { image: cardImage4, title: `Product Positioning & Packaging` },
      { image: cardImage5, title: `Product Planning & Execution` }
    ]
  }
];

export default ResearchData;
