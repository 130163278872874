import cardImg1 from "../../../image/productpages/skalebot/cardImg1.svg";
import cardImg2 from "../../../image/productpages/skalebot/cardImg2.svg";
import cardImg3 from "../../../image/productpages/skalebot/cardImg3.svg";
import cardImg4 from "../../../image/productpages/skalebot/cardImg4.svg";
import cardImg5 from "../../../image/productpages/skalebot/cardImg5.svg";
import cardImg6 from "../../../image/productpages/skalebot/cardImg6.svg";
import marketingImageSVG from "../../../image/productpages/skalebot/marketingImage.svg";
import marketingImage1 from "../../../image/productpages/skalebot/marketingImage1.jpg";
import marketingImage2 from "../../../image/productpages/skalebot/marketingImage2.jpg";
import marketingImage3 from "../../../image/productpages/skalebot/marketingImage3.jpg";

export const introData = {
  title: "Skalebot",
  subTitle: "The Smart Way of doing Business",
  content: [
    "Scale your <b>B</b>uisness sales. <b>O</b?pportunities. <b>T</b>argets",
    "Trusted Sales Assistant to boost your Business"
  ],
  buttons: [
    { label: "VISIT WEBSITE", bgColor: "bg-white" },
    { label: "SCHEDULE DEMO", bgColor: "bg-black text-white" }
  ]
};

export const videoData = {
  title: "Take a video tour of Skalebot",
  // subTitle: "Explore our product extensively in a video walkthrough",
  subTitle: "to take a closer look at our product",
  videoSrc: "https://www.youtube.com/embed/c_Q_RwU57iM?si=uvVG2KeNbZJNfAeZ"
};

export const benifitsData = [
  {
    image: cardImg1,
    title: "Personalized Catalogs",
    description:
      "Craft and share personalized product catalogs effortlessly, enhancing your sales strategy."
  },
  {
    image: cardImg2,
    title: "Conversion Rate Boost",
    description: `Skalebot's AI-driven lead tracking elevates your conversion rate, helping you meet targets more efficiently.
    `
  },
  {
    image: cardImg3,
    title: "Interaction Quality",
    description: `A dependable sales assistant that consistently delivers high-quality interactions, ensuring a positive experience for your customers.`
  },
  {
    image: cardImg4,
    title: "Instant Responses",
    description: `Get information at your fingertips with just two clicks, ensuring quick and efficient customer interactions`
  },
  {
    image: cardImg5,
    title: "Sustainable Growth",
    description: `Optimize your market reach and ensure long-term business sustainability with Skalebot's powerful capabilities`
  },
  {
    image: cardImg6,
    title: "Agent Engagement",
    description: `Easily connect with a human sales agent for additional information or details  following chatbot interaction.`
  }
];

export const MarketingData = [
  {
    image: marketingImage1,
    publishBy: "Youtube • 4 months ago",
    publishTitle: "Boost Customer Engagement",
    publishDescription:
      "Struggling with customer engagement and sales inquiries? Learn about Skalebot",
    badges: ["Skalebot Pro", "Management", "Ulitimate Business"],
    url: "https://youtu.be/pQ92QtgEOeU?si=rn7AGAQKTJlXQWP9"
  },
  {
    image: marketingImage2,
    publishBy: "Linkedin • 2 days ago",
    publishTitle: "Drives For Sales Growth",
    publishDescription:
      "Skalebot helps Goldprince Jewellery to tackle customer support challenges and boost Sales",
    badges: ["Skalebot Pro", "Support Challenge", "Boost Sales"],
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7089549506509213696"
  },
  {
    image: marketingImage3,
    publishBy: "Linkedin • 2 days aago",
    publishTitle: "Strategies and Approaches",
    publishDescription:
      "The difference between Traditional marketing and WhatsApp Marketing",
    badges: ["Skalebot Pro", "Whatsapp Marketing", "Stratagie"],
    url: "https://www.linkedin.com/feed/update/urn:li:activity:6997791913969926144"
  }
];
