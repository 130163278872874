import React from "react";
import classes from "./circularCard.module.css";

const CircularCard = ({ title, backgroundColor }) => {
  return (
    <div
      className={classes.circularCards}
      style={{ backgroundColor: backgroundColor }}
    >
      <p className={classes.circularCardTitle}>{title}</p>
    </div>
  );
};

export default CircularCard;
