import React from "react";
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const CustomCarousel = ({ url, className, style = {} }) => {
  return (
    <div>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        interval={2000}
        showArrows={false}
        showStatus={false}
        showIndicators={true}
        infiniteLoop={true}
        centerMode={false}
        className={className[0]}
      >
        {url.map((img, index) => {
          return (
            <div key={index} className={className[1]}>
              <img src={img} alt="carousel-img" title="Carousel Images" className="carousel-image" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
