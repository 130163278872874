import React from "react";
import "./liStyle.css";

const LiList = ({ description, listItem, className, style = {} }) => {
  return (
    <div>
      <div className={className} style={style}>
        {description ? <p className={`li-content`}>{description}</p> : ""}
        <ul className={`li-container mb-2 `}>
          {listItem.map((item, index) => (
            <div key={index} className="d-flex text-start gap-2 ">
              <div className="blackdot"></div>
              <li className="text-start li-content">{item}</li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LiList;
