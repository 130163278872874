import researchSvg from "../../../image/homePage/ourServices/researchIcon.svg";
import designSvg from "../../../image/homePage/ourServices/designIcon.svg";
import developmentSvg from "../../../image/homePage/ourServices/developmentIcon.svg";
import VRAnd3DNewSvg from "../../../image/homePage/ourServices/vr&3d.svg";

const services = [
  {
    title: "Research",
    icon: researchSvg,
    description: `We use data-driven research to help you understand your target audience and develop products and services that meet their needs.`,
    url: "/services/research"
  },
  {
    title: "Design",
    icon: designSvg,
    description: `We create visually appealing and user-friendly designs to help your business grow.`,
    url: "/services/design"
  },
  {
    title: "Development",
    icon: developmentSvg,
    description: `We help you bring your ideas to life with our custom software development services.`,
    url: "/services/development"
  },
  {
    title: "AR & VR",
    icon: VRAnd3DNewSvg,
    description: `We create engaging and immersive VR experiences to help you market your products and services, train your employees, and educate your customers.`,
    url: "/services/AR&VR"
  }
];

export default services;
