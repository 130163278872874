import React, { useState } from "react";
import classes from "./SkaleWork.module.css";
import { ReactComponent as SkaleWorkLogo } from "../../../image/homePage/ourProducts/skaleWorkIcon.svg";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { videoData } from "../../../data/OurProductsData/SkaleWorkData/SkaleWorkData";
import { benifitsData } from "../../../data/OurProductsData/SkaleWorkData/SkaleWorkData";
import { MarketingData } from "../../../data/OurProductsData/SkaleWorkData/SkaleWorkData";
import InnerNav from "../../../components/InnerNav/InnerNav";
import CustomModal from "../../../components/CustomModal/CustomModal";
import coverImg from "../../../image/productpages/skaleWork/cover.jpg";

const Skalework = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };
  const itemslistnav = [
    { label: "SkaleWork", path: "skalework", url: "/products/skalework" },
    { label: "Skalebot", path: "skalebot", url: "/products/skalebot" }
    // {
    //   label: "Stock Inventory",
    //   path: "stockinventory",
    //   url: "/products/stockinventory"
    // },
    // { label: "Skale Bill", path: "skalebill", url: "/products/skalebill" }
  ];

  const defaultValues = {
    name: "",
    email: ""
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="text-danger  ">{errors[name].message}</small>
      )
    );
  };

  const sendWhatsAppMessage = (formData) => {
    const { name, email } = formData;

    // const phoneNumber = "917987030482";
    const phoneNumber = "918878183433";
    const messageText = `Name: ${name}%0A email Id: ${email}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappUrl, "_blank");
  };

  const submithandler = (e) => {
    sendWhatsAppMessage(e);
    setValue("name", "");
    setValue("email", "");
  };

  return (
    <div className={` ${classes.mainContainer} `}>
      {showModal && (
        <CustomModal
          title="Schedule A Demo"
          description="Do you have a question? We’re here to help you find the answers. Get in touch with us below."
          showModal={showModal}
          onHideModal={handleHideModal}
        />
      )}
      <div className={classes.innerNav}>
        <InnerNav itemslist={itemslistnav} />
      </div>
      <div className={`${classes.introContainer} `}>
        <div className={classes.leftIntro}>
          {/* <div className={classes.headingContainer}> */}

          <SkaleWorkLogo />
          <h2 className={classes.skalebotSubTitle}>Work Smart with</h2>
           <h2 className={classes.skalebotTitle}>SkaleWork </h2>

          <div className={classes.rightIntroMobile}>
            {/* <SkalebotWhatsapp /> */}
            <img src={coverImg} alt="skalebotCover" title="Skalework Cover" />
          </div>

          <div className={`${classes.buttonContainer}`}>
            <a href="https://skalework.com/" target="_blank" rel="noreferrer">
              {" "}
              <button className={`${classes.button} bg-white text-black`}>
                VISIT WEBSITE
              </button>
            </a>
            <button
              className={`${classes.button} bg-black text-white`}
              onClick={handleShowModal}
            >
              SCHEDULE DEMO
            </button>
          </div>
        </div>
        <div className={classes.rightIntro}>
          {/* <SkalebotWhatsapp /> */}
          <img src={coverImg} alt="skalebotCover" title="Skalework Cover" />
        </div>
      </div>

      <div className={classes.benifitsContainer}>
        <h2 className={classes.benifitsHeading}>
          Why SkaleWork Should Be Your Business's Choice
        </h2>
        <div className={`${classes.scrollableContainer}`}>
          <div className={`${classes.cardsContainer} `}>
            {benifitsData.map((item, index) => {
              return (
                <div key={index} className={classes.cardWraper}>
                  <div className={`${classes.customCard} gap-2`}>
                    <img
                      src={item.image}
                      alt={`item-${index}`}
                      title="card image"
                      className="mb-2"
                    />
                    <h3 className={classes.cardTitle}>{item.title}</h3>
                    <p className={classes.cardDescription}>
                      {item.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.videoContainer}>
        <h2 className={classes.videoTitle}>{videoData.title}</h2>
        <h3 className={classes.videoText}>{videoData.subTitle}</h3>
        <iframe
          width="560"
          height="315"
          src={videoData.videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className={classes.iframe}
        ></iframe>
      </div>

      <div className={classes.marketingContainer}>
        <h2 className={classes.marketingTitle}>Marketing</h2>
        <div className={`${classes.scrollableContainer}  `}>
          <div className={classes.cardsContainer}>
            {MarketingData.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.anchorTag}
                >
                  <div className={classes.cardWraper}>
                    <div className={`${classes.marketingCard}`}>
                      <div className={classes.marketingImage}>
                        <img
                          src={item.image}
                          alt="marketing"
                          title="Marketing Image"
                          className={classes.marketingImage}
                        />
                      </div>
                      {/* <p className={classes.publishBy}>{item.publishBy}</p> */}
                      <h3 className={classes.publishTitle}>
                        {item.publishTitle}
                      </h3>
                      <h3 className={`${classes.publishDescription}`}>
                        {item.publishDescription}
                      </h3>
                      <div className={`${classes.badgesContainer} gap-2`}>
                        <p className={`${classes.badges} rounded-pill`}>
                          {item.badges[0]}
                        </p>
                        <p className={`${classes.badges} rounded-pill`}>
                          {item.badges[1]}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.contactUsContainer}>
         <h2 className={classes.contactUsTitle}>
          Learn more, arrange a call back!
         </h2>
        <div className={`${classes.formContainer} `}>
          <div className={`${classes.inputDiv} text-start `}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Please provide name" }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  // disabled={!edit && mode === "update"}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  //   useGrouping={false}
                  locale="en-IN"
                  placeholder="Your Name"
                  style={{
                    background: "transparent"
                  }}
                  className={`
                    ${classes.inputStyle}
                     rounded-pill
                    ${fieldState.invalid ? "border border-danger" : ""}
                  `}
                />
              )}
            />
            {getFormErrorMessage("name")}
          </div>
          <div className={`${classes.inputDiv} text-start `}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Please provide email",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Enter a valid Email Id"
                }
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  // disabled={!edit && mode === "update"}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  //   useGrouping={false}
                  locale="en-IN"
                  placeholder="Your Email"
                  style={{
                    background: "transparent"
                  }}
                  className={`
                    ${classes.inputStyle}
                      rounded-pill
                    ${fieldState.invalid ? "border border-danger" : ""}
                  `}
                />
              )}
            />
            {getFormErrorMessage("email")}
          </div>
          <button
            className={classes.submitButton}
            onClick={handleSubmit(submithandler)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Skalework;
