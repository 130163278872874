import React from "react";
import classes from "./development.module.css";
import CustomIntroduction from "../../../components/ServicesComponent/Introduction/CustomIntroduction";
import ProductDevelopment from "../../../components/ServicesComponent/DevelopmentProduct/ProductDevelopment";
import Capabilities from "../../../components/ServicesComponent/DevelopmentCapabilities/Capabilities";
import WhatWeThink from "../../../components/ServicesComponent/DevelopmentWhatWethink/WhatWeThink";
import WhatWeThinkScroll from "../../../components/ServicesComponent/developmentWhatWeThinkScroll/WhatWeThinkScroll";
import DevelopmentData from "../../../data/ServicesData/DevelopmentData/DevelopmentData";
import { Helmet } from 'react-helmet-async';

const Development = () => {
  return (
    <div className={` ${classes.mainContainer}`}>
      <Helmet>
        <title>Deskala- development </title>
        <link rel="canonical" href="https://www.deskala.in/services/development" />
        <meta name="description" content="This is a Deskala website development page" />
        <meta property="og:title" content=">Deskala- development" />
        <meta property="og:description" content=">At Deskala, we are passionate about translating business challenges into innovative solutions through our strategic Product Development approach" />
        <meta property="og:image" content="https://www.deskala.in/static/media/deskalaHeaderIcon.6d9e2cd43118c8756b04fa3bdd9650ce.svg" />
      </Helmet>
      {DevelopmentData.map((item, index) => {
        switch (item.componentType) {
          case "introduction":
            return (
              <CustomIntroduction
                key={index}
                title={item.title}
                heading1={item.heading1}
                heading2={item.heading2}
                spanTitle1={item.span1}
                img={item.img}
                itemslist={item.itemslist}
              />
            );
          case "whoWeAre":
            return (
              <ProductDevelopment
                key={index}
                heading={item.heading}
                description={item.description}
              />
            );

          case "capabilities":
            return (
              <Capabilities
                key={index}
                heading={item.heading}
                description={item.description}
                cards={item.cards}
              />
            );

          default:
            <></>;
            break;
        }
      })}
    </div>
  );
};

export default Development;
