import React, { useState, useEffect } from "react";
// import "./style.css";
import classes from "./imageSlider.module.css";

const ImageSlider = ({ url }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  useEffect(() => {
    // Create an array to store image elements
    const images = url.map((imageSrc, index) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve({ index, img });
        };
        img.src = imageSrc;
      });
    });

    // Wait for all images to load
    Promise.all(images)
      .then((loadedImages) => {
        // Ensure they are sorted in the original order
        loadedImages.sort((a, b) => a.index - b.index);
        setImagesLoaded(true);
      })
      .catch((error) => {
        // Handle errors if necessary
      });
  }, [url]);
  return (
    <div className={classes.imageSliderContainer}>
      <div className={classes.imageContainer}>
        {imagesLoaded ? (
          url.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="slider"
              title="Image slider image"
              className={classes.sliderImage}
            />
          ))
        ) : (
          <div className={classes.loader}></div>
        )}
      </div>
    </div>
  );
};

export default ImageSlider;
