import React from "react";
import classes from "./index.module.css";
import ContactDetails from "./ContactDetails";
import ContactForm from "./ContactForm";

const index = () => {
  return (
    <div id="enquiry" className={classes.container}>
      <div className={classes.subContainer}>
        <ContactForm />
        <ContactDetails />
      </div>
    </div>
  );
};

export default index;
