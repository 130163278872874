import React, { useState, useEffect } from "react";
import headingBottomSvg from "../../image/homePage/headingBottom.svg";
import classes from "./clients.module.css";
import clients from "../../data/HomePageData/ClientsData/clients";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Marquee from "react-fast-marquee"; // Import the Marquee component

const Clients = () => {
  return (
    <div id="clients" className={`p-3 p-md-5 ${classes.ourClientBackground}`}>
      <div className="text-center">
         <h2 className={`font-bold mb-0 ${classes.titleText} text-white`}>
          Our Clients
         </h2>
        <img src={headingBottomSvg} alt="ourServicesBottom" title="bottom line" />
      </div>

      <div className={classes.logosAnimation}>
        <Marquee speed={40} className="">
          {" "}
          {/* Adjust the speed as needed */}
          {clients.map((logo, index) => (
            <img
              key={index}
              src={logo.icons}
              alt="logo"
              title="Brand Icon"
              className={classes.logosSlideImg}
            />
          ))}
        </Marquee>
      </div>

      <div className={`${classes.clientContainer} py-5  `}>
        {clients.map((icon, index) => (
          <div key={index} className={classes.logoContainer}>
            <img
              src={icon.icons}
              alt={`udemyLogo-${index}`}
              title="Brand Icon"
              className={classes.logoClient}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;
