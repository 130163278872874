import React from "react";
import testimonialSideSvg from "../../image/homePage/testimonials/testimonialCover.svg";
import classes from "./testimonials.module.css";
import testimonialData from "../../data/HomePageData/TesimonialsData/testimonial";
import { Carousel } from "react-bootstrap";

const Testimonial = () => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <Carousel
          controls={false}
          indicators={true}
          interval={8000}
          className={classes.carouselContainer}
        >
          {testimonialData.map((data, index) => {
            return (
              <Carousel.Item key={index} className={classes.carouselItem}>
                <h2 className={classes.testimonial}>{data.testimonial}</h2>
                <div className={classes.authorContainer}>
                  <h3 className={classes.testimonialAuthor}>{data.person}</h3>
                  <h3 className={classes.testimonialAuthor}>{data.position}</h3>
                  <h3 className={classes.testimonialAuthor}>{data.company}</h3>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
        <style>
          {`
          // .carousel-control-prev,
          // .carousel-control-next {
          //   width: auto;
          //   position: absolute;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   background-color: transparent;
          //   border: none;
          //   font-size: 24px;
          //   color: #000;
          // }

          // .carousel-control-prev:hover,
          // .carousel-control-next:hover {
          //   background-color: transparent;
          //   color: #555;
          // }

          /* Style indicators */
          .carousel-indicators {
            margin-top:20px
            bottom: 0px;
            

            
          }

          .carousel-indicators [data-bs-target] {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            padding: 0;
            text-indent: -999px;
            cursor: pointer;
            opacity: .5;
            transition: opacity .6s ease;
        }

          .carousel-indicators .active {
            background-color: #000; /* Active color (black) */
          }
        `}
        </style>
        <div className={classes.testimonialContainer}>
          <div className="w-full flex flex-column align-items-center justify-content-center  ">
            <div className="w-full p-1 text-center">
               <h2 className={classes.testimonialTitle}>Testimonials </h2>
            </div>
            <div className="w-full p-2 text-center">
              <p className={classes.testimonialDescriptionWeb}>
                See why we're the best choice for you
              </p>
              <p className={classes.testimonialDescriptionMobile}>
                See why we're the best <br></br>choice for you
              </p>
            </div>
          </div>
          <div className="w-full flex justify-content-center">
            <img
              src={testimonialSideSvg}
              alt="testimonial"
              title="testimonials"
              className={classes.testimonialImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
