import coverImage from "../../../image/CaseStudy/udemy/coverImage/coverImage.jpg";
import brandImg from "../../../image/CaseStudy/udemy/brandImage/brandImage.jpg";
import sliderOneImage1 from "../../../image/CaseStudy/udemy/researchAnalysisSlider/image1.jpg";
import sliderOneImage2 from "../../../image/CaseStudy/udemy/researchAnalysisSlider/image2.jpg";
import sliderTwoImage1 from "../../../image/CaseStudy/udemy/reasearchTimelineSlider/image1.jpg";
import sliderTwoImage2 from "../../../image/CaseStudy/udemy/reasearchTimelineSlider/image2.jpg";
import sliderThreeImage1 from "../../../image/CaseStudy/udemy/finalReportSlider/image1.jpg";
import sliderThreeImage2 from "../../../image/CaseStudy/udemy/finalReportSlider/image2.jpg";

const udemyCaseStudyData = [
  {
    componentType: "title",
    context: "Udemy India Research",
    className: "title"
  },

  {
    componentType: "CoverImage",
    id: "coverImage",
    // url: "https://deskala.in/wp-content/uploads/2021/09/api-doc.png",
    url: coverImage,
    className: "coverImg"
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Business Problem",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `In 2023 the ed-tech market is going through a lot of issue, and India is one of the largest consumer of Online platform Udemy. This triggered a research objective of doing
    a deeper consumer behaviour analysis with respect to Online learning. 
   Deskala conducted a study across various Tier 1 cities across India.`,
    className: "content"
  },
  {
    componentType: "title",
    id: "requirements",
    context: "Research Preparation & Planning",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Since there were multiple kind of
    research that was expected to be
    done e.g. Focus Interviews, Usability
    Evaluation & Business User Research. There was a detailed planning that
    was done to achieve the desired
    outcome.`,
    className: "content"
  },
  {
    componentType: "CoverImage",
    id: "brandingLogo",
    url: brandImg,
    className: "coverImg"
  },

  {
    componentType: "title",
    id: "solutions",
    context: "Research Analysis",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Deskala used Thematic analysis of the
    data by creating a Thematic coding
    sheet that can help us execute such a
    diverse data analysis across various
    user sections.`,
    className: "content"
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [sliderOneImage1, sliderOneImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  },

  {
    componentType: "title",
    id: "benifits",
    context: "Research Execution",
    className: "sub-heading"
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [sliderTwoImage1, sliderTwoImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  },
  {
    componentType: "liList",
    id: "requirementList",
    listItem: [
      `The study was conducted across 5
      Indian cities like Delhi, Mumbai, Pune,
      Hyderabad and Bangalore.`,
      `Deskala also conducted Usability
    evaluation with some of the
    participants to understand their
    challenges with the current
    application on Mobile phone.`,
      `Since Udemy also offers a business
    focused services, we also interviewed
    various corporates across India to get
    a better perspective of how learning
    is handled in Corporates.`
    ],
    className: ["requirement-container", "arrow", "requirement-content"]
  },
  {
    componentType: "title",
    id: "feedback",
    context: "Final Report",
    className: "sub-heading"
  },
  {
    componentType: "description",
    id: "introductionId",
    context: `Since Udemy is a Data driven
    company we submitted a
    quantitative + qualitative data report
    that can help them make more
    effective decision with respect to the
    target audience and their learning
    requirements.`,
    className: "content"
  },
  {
    componentType: "imageSlider",
    id: "carouselFirst",
    url: [sliderThreeImage1, sliderThreeImage2],
    className: [
      "carousel-wrapper",
      "carousel-image-wrapper",
      "carousel-image-wrapper-img"
    ]
  }
];

export default udemyCaseStudyData;
