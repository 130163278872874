import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "./style.css";
import { useLocation } from "react-router-dom";

import HomePage from "./HomePage";
const Home = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div>
      <header>
        <Header />
      </header>
      {currentPath === "/" ? <HomePage /> : <Outlet />}
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;
