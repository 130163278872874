import skalebotSvg from "../../../image/homePage/ourProducts/skaleBotIcon.svg";

import skaleworkSvg from "../../../image/homePage/ourProducts/skaleWorkIcon.svg";

const products = [
  {
    icon: skaleworkSvg,
    title: "Skalework",
    // users: "+4k users",
    description: ` Your go-to tool for managing every stage of production, from the initial design to the final product assembly. Keeps your manufacturing process organized and efficient.`,
    buttonLabel: "Explore More",
    url: "/products/skalework"
  },
  {
    icon: skalebotSvg,
    title: "SkaleBot",
    // users: "+4k users",
    description: `Your trusted AI chatbot. With integrated WhatsApp support, skalebot ensures that you can reach your right audience and empowers you to take control of your sales efforts.`,
    buttonLabel: "Explore More",
    url: "/products/skalebot"
  },
  {
    // icon: skalebotSvg,
    title: "Skale Inventory",
    // users: "+4k users",
    description: `Upcoming software solutions from Deskala`,
    buttonLabel: "Future Products",
    url: "/products/stockinventory"
  },
  {
    // icon: skalebotSvg,
    title: "Skale Bill",
    // users: "+4k users",
    description: `Upcoming software solutions from Deskala`,
    buttonLabel: "Future Products",
    url: "/products/skalebill"
  }
];

export default products;
