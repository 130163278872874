import React from "react";
import classes from "./design.module.css";
import CustomIntroduction from "../../../components/ServicesComponent/Introduction/CustomIntroduction";
import BrandDesignStrategy from "../../../components/ServicesComponent/DesignBrandDesignStrategy/BrandDesignStrategy";
import UxDesign from "../../../components/ServicesComponent/DesignUxDesign/UxDesign";
import DesignThinkingWorkshop from "../../../components/ServicesComponent/DesignThinking/DesignThinkingWorkshop";
import DesignThinkingScroll from "../../../components/ServicesComponent/DesignThinkingScroll/DesignThinkingScroll";
import DesignData from "../../../data/ServicesData/DesignData/DesignData";
import { Helmet } from 'react-helmet-async';
const Design = () => {
  return (
    <div className={` ${classes.mainContainer}`}>
       <Helmet>
        <title>Deskala- design </title>
        <link rel="canonical" href="https://www.deskala.in/services/design" />
        <meta name="description" content="This is a Deskala website design page" />
        <meta property="og:title" content=">Deskala- services" />
        <meta property="og:description" content="Bringing your innovative ideas to life requires not just creativity but also a mastery of execution." />
        <meta property="og:image" content="https://www.deskala.in/static/media/deskalaHeaderIcon.6d9e2cd43118c8756b04fa3bdd9650ce.svg" />
      </Helmet>
      {DesignData.map((item, index) => {
        switch (item.componentType) {
          case "introduction":
            return (
              <CustomIntroduction
                key={index}
                heading1={item.heading1}
                heading2={item.heading2}
                spanTitle2={item.span2}
                img={item.img}
                itemslist={item.itemslist}
              />
            );
          case "brandDesignStrategy":
            return (
              <BrandDesignStrategy
                key={index}
                heading={item.heading}
                description={item.description}
                cards={item.circularCards}
              />
            );

          case "uxDesign":
            return (
              <UxDesign
                key={index}
                heading={item.heading}
                description={item.description}
                cards={item.cards}
              />
            );
          case "designThinkingWorkshop":
            return (
              <DesignThinkingWorkshop
                key={index}
                heading={item.heading}
                description={item.description}
                images={item.images}
              />
            );

          case "designThinkingScroll":
            return (
              <DesignThinkingScroll
                key={index}
                heading={item.heading}
                description={item.description}
                images={item.images}
              />
            );

          default:
            <></>;
            break;
        }
      })}
    </div>
  );
};

export default Design;
